<template>
  <div style="width: 100%"
    class="fill-height">
    <!-- <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži artikle"
      ></Search-box
    ></v-row> -->
    <v-container class="pt-0">
      <v-row v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center">
        <v-col cols="12"
          sm="11"
          lg="11"
          :class="['px-0']">
          <v-card v-for="(field, i) in fields"
            :key="i"
            hover>
            <v-row :class="[
              'ma-0',
              field.status === 'OK'
                ? 'status-ok-exp'
                : `${field.status === 'PROCESSING'
                  ? 'status-processing-exp'
                  : 'status-failed-exp'
                }`,
            ]"
              style="height: 48px !important">
              <div style="width: 15% !important"
                :class="[densityPadding]"
                class="pl-3">
                <div class="caption grey--text">Labela</div>
                <div class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${field.id}`, field)"
                  @mouseleave="field[`hover-${field.id}`] = false"
                  :id="`hover-${field.id}`"
                  :ref="`hover-${field.id}`">
                  {{ $options.filters.capitalize(field.label) }}
                </div>
              </div>
              <div style="width: 25% !important"
                :class="[densityPadding]">
                <div class="caption grey--text">Tip</div>
                <div>
                  {{ showType(field.field_type) }}
                </div>
              </div>
              <div :class="[densityPadding]"
                style="width: 12% !important">
                <div class="caption grey--text text-center">Obavezno polje</div>
                <div class="text-center">
                  {{ field.mandatory ? "DA" : "NE" }}
                </div>
              </div>
              <div :class="[densityPadding]"
                style="width: 15% !important">
                <div class="caption grey--text text-center">
                  Zadana vrijednost
                </div>
                <div class="text-center">
                  {{ field.default_value }}
                </div>
              </div>
              <div :class="[densityPadding]"
                style="width: 15% !important">
                <div class="caption grey--text text-center">
                  Koristi se na printu
                </div>
                <div class="text-center">
                  {{ field.printable ? "DA" : "NE" }}
                </div>
              </div>
              <div style="width: 8% !important"
                :class="[densityPadding]">
                <div class="caption grey--text text-center">Status</div>
                <div :class="[
                  field.status === 'OK'
                    ? 'success--text'
                    : `${field.status === 'PROCESSING'
                      ? 'info--text'
                      : 'error--text'
                    }`,
                  'text-center',
                ]">
                  {{ field.status }}
                </div>
              </div>
              <div style="width: 5% !important"
                :class="[densityPadding]">
                <div class="d-flex align-start justify-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="field.status !== 'OK'"
                        @click="openEditField(field)"
                        @keypress.enter="openEditField(field)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi parametar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="field.status !== 'OK'"
                        @click="deleteField(field)"
                        @keypress.enter="deleteField(field)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Obriši parametar</span>
                  </v-tooltip>
                </div>
              </div>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
        <add-field ref="addField"
          @refreshFields="refresh()"></add-field>
        <edit-field ref="editField"
          @refreshFields="refresh()"></edit-field>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
// import EventBus from '@/plugins/event-bus'
import AddField from '@/modules/point-of-sale/components/workorder/AddField'
import EditField from '@/modules/point-of-sale/components/workorder/EditField'

export default {
  name: 'Fields',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { AddField, EditField },
  data: () => ({
    dialog: false,
    loading: false,
    totalArticles: 0,
    lastiItem: false,
    fields: [],
    listeners: [],
    multiplier: 0,
    selectedLocation: undefined,
    locations: [],
    showLocationSelector: false,
    choosenText: '',
    innerSearch: undefined,
    barcode: [],
    // searchEmitDestination: 'locationStaffSearch',
    lastDocSnapshot: null
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  async mounted () {
    // DOHVATI SVE LOKACIJE ZA FIRMU
    // await this.getLocations()

    // EventBus.$on(this.searchEmitDestination, (searchObject) => {
    //   if (!searchObject.triGramObject) {
    //     this.lastDocSnapshot = null
    //     this.detachListeners()
    //     this.resetHandler()
    //   } else {
    //     this.getStaffNew(searchObject.triGramObject)
    //   }
    // })

    this.getField()
  },
  methods: {
    showType (fieldType) {
      switch (fieldType) {
        case 'textfield':
          return 'Polje za unos teksta'
        case 'textarea':
          return 'Veliko polje za unos teksta'
        case 'dropdown':
          return 'Odabir predefiniranih vrijednosti'
        case 'checkbox':
          return 'Potvrdni okvir'
        case 'radio':
          return 'Radio gumb'
        case 'date':
          return 'Datum'
      }
      return ''
    },
    refresh () {
      setTimeout(() => {
        this.fields = []
        this.getField()
        this.$forceUpdate()
      }, 1000)
    },

    openAddField () {
      if (this.fields && this.fields.length > 0) {
        const maxOrderNumber = Math.max(...this.fields.map(field => field.order_number))
        this.$refs.addField.open(maxOrderNumber + 1)
      } else {
        this.$refs.addField.open(1)
      }
    },

    openEditField (field) {
      this.$refs.editField.open(field)
    },

    deleteField (field) {
      this.confirm({
        title: 'Brisanje',
        message: `Jeste li sigurni da želite obrisati polje '${field.label}'?`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'OBRIŠI',
          cancelText: 'ODUSTANI'
        }
      }).then(async (resp) => {
        this.showLoader()
        this.confirmClose()
        if (resp) {
          var workorderApi = functions.httpsCallable('workorderapi')

          var params = {
            action: {
              operation: 'delete',
              entity: 'field',
              resource_id: '',
              params: {
                id: field.id,
                company_id: state.getCurrentCompany().id
              }
            }
          }

          workorderApi(
            params
          )
            .then((result) => {
              this.hideLoader()
              if (result.data.code === 200) {
                this.refresh()
                this.showMsgBox({
                  text: 'Uspješna izmjena',
                  actions: ['cancel'],
                  cancelBtnText: 'OK',
                  color: 'error'
                })
              } else {
                this.showMsgBox({
                  text: 'Dogodila se greška, neuspješna izmjena',
                  actions: ['cancel'],
                  cancelBtnText: 'OK',
                  color: 'error'
                })
              }
            })
        } else {
          this.confirmClose()
        }
      })
    },

    async getField () {
      const snapshot = await df.doc(`workorder_fields/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()

        this.fields = Object.keys(document.fields).map(key => {
          return { ...document.fields[key] }
        })

        this.fields.sort((a, b) => a.label > b.label ? 1 : -1)
      }
    },

    getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
              if (state.getPointOfSale().id !== undefined && loc.id === state.getPointOfSale().id) {
                this.selectedLocation = loc
                this.getStaffNew()
              }
            })
          }
        })
    },

    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },

    openDialog () {
      this.dialog = true
    }

  }
}
</script>
