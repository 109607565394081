<template>
  <v-container fluid
    :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3"
        width="97%"
        height="70">
        <v-row>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportFromMenu"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportToMenu"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-select outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object></v-select>
          </div>

          <div style="
              width: 25% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
            class="d-flex justify-center">
            <v-autocomplete class="pb-1"
              outlined
              clearable
              dense
              label="Odaberite artikl"
              :items="items"
              item-text="name"
              item-value="id"
              return-object
              hide-details
              v-model="selectedItem"
              @click:clear="resetPagination"></v-autocomplete>
            <v-btn :disabled="locationNotSelected || !selectedItem"
              class="ml-3 mt-0"
              @click="searchFetch()"><v-icon>mdi-magnify</v-icon></v-btn>

          </div>

          <div style="
              max-width: 25% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
            ">
            <v-btn class="ml-3"
              :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">

        </v-row>
      </v-card>
    </div>
    <div class="d-flex justify-center">
      <div id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        ">
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma"
          style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Codes by Quackit.com -->

        <!--  -->
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title">
            <thead>
              <tr class="pb-0 mb-0">
                <th colspan="6"
                  class="pb-0 mb-0">
                  <p style="font-weight: bold; font-size: 25px">
                    Skladišna kartica
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <v-container class="d-flex justify-center align-center">
            <v-row style="min-width: 50% !important; max-width: 50% !important;"
              justify="center"
              align="center">
              <v-tabs v-if="selectedItem"
                v-model="activeTab"
                class="w-100"
                justify="space-around"
                style="width: 100%"
                @change="onTabChange">
                <v-tab class="flex-grow-1 text-center">Primke</v-tab>
                <v-tab class="flex-grow-1 text-center">Otpremnice</v-tab>
              </v-tabs>
            </v-row>
          </v-container>
          <v-tabs-items v-if="(primke && primke.length > 0) || (otpremnice && otpremnice.length > 0)"
            v-model="activeTab">
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <v-data-table :fixed-header="true"
                    :headers="primkeHeaders"
                    :items="primke"
                    :show-header="false"
                    :items-per-page="-1"
                    :disable-pagination="true"
                    class="elevation-1 fixed-height-table"
                    :hide-default-footer="true"
                    height="55vh">
                    <template #[`item.price`]="{ item }">
                      {{ (item.price / 100).toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' }}
                    </template>

                    <template #[`item.quantity`]="{ item }">
                      {{ (item.quantity / 1000).toLocaleString('hr-HR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }) }}
                    </template>

                    <template #footer>
                      <div class="footer-wrapper">

                        <div class="text-center pt-2 pb-2"
                          v-if="hasMoreItems">

                          <v-btn class="white--text okButton font-weight-bold rounded-card"
                            style="font-size: 12px"
                            width="20%"
                            color="loginButton"
                            height="45"
                            x-large
                            id="registerBtn"
                            @click="loadMore('deliveryNotes')">
                            Učitaj još...
                          </v-btn>
                        </div>
                      </div>

                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card>
                <v-card-text>
                  <v-data-table :fixed-header="true"
                    :headers="otpremniceHeaders"
                    :items="otpremnice"
                    :items-per-page="10"
                    :disable-pagination="true"
                    class="elevation-1 fixed-height-table"
                    :hide-default-footer="true"
                    height="55vh">
                    <template #[`item.price`]="{ item }">
                      {{ (item.price / 100).toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' }}
                    </template>

                    <template #[`item.quantity`]="{ item }">
                      {{ (item.quantity / 1000).toLocaleString('hr-HR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }) }}
                    </template>

                    <template #footer>
                      <div class="text-center pt-2 pb-2"
                        v-if="hasMoreItems">
                        <v-btn class="white--text okButton font-weight-bold rounded-card"
                          style="font-size: 12px"
                          width="20%"
                          color="loginButton"
                          height="45"
                          x-large
                          id="registerBtn"
                          @click="loadMore('shipmentNotes')">
                          Učitaj još...
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </div>
        <!-- <div justify="center">
          <table justify="center"
            style="width: 100% !important; table-layout: fixed;"
            border="1"
            cellspacing="0"
            bordercolor="#cacccb"
            id="tableTraffic"
            class="tableTraffic"
            v-if="showTableData">
            <colgroup>
              <col style="width: 20%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
            </colgroup>
            <tbody style="min-width: 100% !important">
              <tr style="text-align: left; font-weight: bold; background-color: #dcdcdc; min-width: 100% !important;">
                <td style="text-align: center;">Artikl</td>
                <td style="text-align: center;">Cijena</td>
                <td style="text-align: center;">Ulaz</td>
                <td style="text-align: center;">Vrijednost</td>
                <td style="text-align: center;">Izlaz</td>
                <td style="text-align: center;">Vrijednost</td>
                <td colspan="2"
                  style="text-align: center">Zaliha</td>
                <td style="text-align: center;">Vrijednost</td>

              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'
import { getReport } from '@/api/report'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: {},
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      location: null,
      locations: [],
      warehouse: null,
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      currency: 'EUR',
      selectedItem: undefined,
      items: [],
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      showTableData: false,
      activeTab: 0,
      primkeHeaders: [
        { text: 'Datum', value: 'created' },
        { text: 'Broj primke', value: 'deliveryNumber' },
        { text: 'Cijena', value: 'price' },
        { text: 'Količina', value: 'quantity' },
        { text: 'Mj. jedinica', value: 'unit' }
      ],
      primke: [],
      otpremniceHeaders: [
        { text: 'Datum', value: 'created' },
        { text: 'Broj računa', value: 'receiptNumber' },
        // { text: 'Cijena', value: 'price' },
        { text: 'Količina', value: 'quantity' },
        { text: 'Mj. jedinica', value: 'unit' }

      ],
      otpremnice: [],
      hasMoreItems: true
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())

    this.getItems()
  },
  methods: {
    searchFetch () {
      if (this.activeTab === 0) {
        this.resetPagination()
        this.fetch('deliveryNotes')
      } else if (this.activeTab === 1) {
        this.resetPagination()
        this.fetch('shipmentNotes')
      }
    },
    onTabChange (newTab) {
      this.showLoader()
      if (newTab === 0) {
        this.resetPagination()
        this.fetch('deliveryNotes')
      } else if (newTab === 1) {
        this.resetPagination()
        this.fetch('shipmentNotes')
      }
    },
    async loadMore (type) {
      await this.fetch(type, true)
    },

    async fetch (type, loadMore = false) {
      this.showLoader()

      try {
        const dateFrom = new Date(this.dateFrom).getTime() / 1000
        var dateTo = new Date(this.dateTo)
        dateTo.setHours(23, 59, 59, 999)
        dateTo = dateTo.getTime() / 1000

        const query = df.collection('warehouses')
          .where('location_id', '==', this.location.id)
          .where('selling_warehouse', '==', true)

        const documentSnapshots = await query.get()

        if (!documentSnapshots.docs?.length) {
          throw new Error('Warehouse not found')
        }

        this.warehouse = documentSnapshots.docs[0].data()

        const payload = {
          dateFrom,
          dateTo,
          report: type,
          warehouseId: this.warehouse.id,
          item: this.selectedItem.id
        }

        if (this.lastDocumentedID) {
          payload.lastDocumentedID = this.lastDocumentedID
        }

        const response = await getReport(payload)

        if (response.status === 200) {
          var newItems = []

          switch (type) {
            case 'deliveryNotes':
              newItems = response.data.data.deliveryNotes

              this.hasMoreItems = newItems.length === 10

              if (loadMore) {
                this.primke.push(...newItems)
              } else {
                this.primke = newItems
              }
              break
            case 'shipmentNotes':
              newItems = response.data.data.shipmentNotes

              this.hasMoreItems = newItems.length === 10

              if (loadMore) {
                this.otpremnice.push(...newItems)
              } else {
                this.otpremnice = newItems
              }
              break
          }

          this.hideLoader()

          this.lastDocumentedID = response.data.data.lastDocumentId

          this.showTableData = false
          this.showItemsData = true
        } else {
          this.hideLoader()
          throw new Error(response.data.data.message)
        }
      } catch (error) {
        this.showMsgBox({
          text: error.message,
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.hideLoader()
      } finally {
        this.hideLoader()
      }
    },

    resetPagination () {
      this.lastDocumentedID = ''
      this.hasMoreItems = true
      this.primke = []
      this.otpremnice = []
    },

    async getItems () {
      this.items = []
      const query = df.collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')
        .where('has_norm', '==', false)

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((it) => {
              this.items.push(it.data())
              this.items = clone(this.items.sort((a, b) => a.name.localeCompare(b.name)))
            })
          }
        })
    },

    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)

      doc = await setPageNumber(doc)

      doc.save('Rekapitulacija robno materijalnih kartica.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Rekapitulacija robno materijalnih kartica.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.location = all
    }
  }
}
</script>
<style scoped>
.blank_row {
  height: 25px !important;
  /* overwrites any other rules */
  background-color: #ffffff;
  border: 0px red;
  border: none !important;
}

.tableTraffic {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}

.v-tabs {
  .v-tab {
    background-color: #fafafa;
    /* skoro bijela */
  }

  .v-tab--selected {
    background-color: #c0c0c0;
    /* još tamnija siva */
  }
}

.footer-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
