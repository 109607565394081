<template>
  <div style="width: 100%" class="fill-height">
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži artikle"
      ></Search-box
    ></v-row>

    <v-container class="pt-0">
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(staff, i) in staffs" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                staff.status === 'OK'
                  ? 'status-ok-exp'
                  : `${
                      staff.status === 'PROCESSING'
                        ? 'status-processing-exp'
                        : 'status-failed-exp'
                    }`,
              ]"
              style="height: 48px !important"
            >
              <v-col
                style="min-width: 50px; max-width: 10%"
                :class="[densityPadding]"
              >
                <div class="caption grey--text">Ime</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${staff.id}`, staff)"
                  @mouseleave="staff[`hover-${staff.id}`] = false"
                  :id="`hover-${staff.id}`"
                  :ref="`hover-${staff.id}`"
                >
                  {{ $options.filters.capitalize(staff.name) }}
                </div>
              </v-col>
              <v-col
                style="min-width: 50px; max-width: 10%"
                :class="[densityPadding]"
              >
                <div class="caption grey--text">Prezime</div>
                <div>{{ $options.filters.capitalize(staff.surname) }}</div>
              </v-col>
              <v-col
                :class="[densityPadding]"
                style="min-width: 100px; max-width: 50%"
              >
                <div class="caption grey--text text-center">Kategorije</div>
                <div class="text-center">
                  {{ showStaffCategories(staff.categories) }}
                </div>
              </v-col>
              <v-col :class="[densityPadding]">
                <div class="caption grey--text text-center">Status</div>
                <div
                  :class="[
                    staff.status === 'OK'
                      ? 'success--text'
                      : `${
                          staff.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                    'text-center',
                  ]"
                >
                  {{ staff.status }}
                </div>
              </v-col>

              <v-col cols="2" md="1" lg="1" :class="[densityPadding]">
                <div class="d-flex align-start justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="staff.status !== 'OK'"
                        @click="openEditStaff(staff)"
                        @keypress.enter="openEditStaff(staff)"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi osoblje</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
        <add-staff ref="addStaff" @refreshStaff="refresh()"></add-staff>
        <edit-staff ref="editStaff" @refreshStaff="refresh()"></edit-staff>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import InfiniteLoading from 'vue-infinite-loading'
import EventBus from '@/plugins/event-bus'
import { collection, query, orderBy, getDocs, where, limit, startAfter } from 'firebase/firestore'
import AddStaff from '@/modules/point-of-sale/components/workorder/AddStaff'
import EditStaff from '@/modules/point-of-sale/components/workorder/EditStaff'

export default {
  name: 'Staffs',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { InfiniteLoading, AddStaff, EditStaff },
  data: () => ({
    dialog: false,
    loading: false,
    totalArticles: 0,
    lastiItem: false,
    staffs: [],
    listeners: [],
    multiplier: 0,

    choosenText: '',
    innerSearch: undefined,
    barcode: [],
    searchEmitDestination: 'companyStaffSearch',
    lastDocSnapshot: null
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  async mounted () {
    document.onkeydown = this.handleKeyEvents

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      if (!searchObject.triGramObject) {
        this.lastDocSnapshot = null
        this.detachListeners()
        this.resetHandler()
      } else {
        this.getStaffNew(searchObject.triGramObject)
      }
    })
  },
  methods: {
    refresh () {
      this.lastDocSnapshot = null
      this.detachListeners()
      this.resetHandler()
    },
    openEditStaff (staff) {
      this.$refs.editStaff.open(staff)
    },
    openAddStaff () {
      this.$refs.addStaff.open()
    },
    showStaffCategories (categories) {
      var cat = ''
      if (categories && categories.length > 0) {
        categories.forEach((category, key) => {
          cat += category

          if (key + 1 < categories.length) {
            cat += ', '
          }
        })
      }

      return cat
    },
    resetHandler () {
      this.staffs = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    async infiniteHandler ($state) {
      const newItemsCount = await this.getStaffNew()
      if (newItemsCount > 0) {
        return $state.loaded() // getStaffNew je vratio vise od nula artikala, znaci da ih ima jos
      }
      return $state.complete() // getStaffNew je vratio 0 artikala, znaci da ih vise nema
    },
    async getStaffNew (triGramObject = undefined) {
      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`meta.${name}`, '==', true))
        )
      }

      if (!triGramObject) {
        searchConstraints.push(orderBy('name', 'asc'))
      }

      if (this.lastDocSnapshot && !triGramObject) {
        searchConstraints.push(startAfter(this.lastDocSnapshot))
      }

      searchConstraints.push(limit(10))

      const constraints = [
        collection(df, `workorder_staff/${state.getCurrentCompany().id}/staff`),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)

      const querySnapshot = await getDocs(q)
      this.lastDocSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1]

      const results = []
      querySnapshot.forEach(doc => {
        var staffIt = doc.data()

        results.push({ _id: staffIt.id, ...staffIt })
      })

      if (triGramObject) {
        this.staffs = results
        return true
      }

      this.staffs.push(...results)

      return results.length
    },

    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },

    openDialog () {
      this.dialog = true
    }

  }
}
</script>
