var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"pt-3 pr-3"},[_c('v-spacer'),_c('Search-box',{attrs:{"emitDestination":_vm.searchEmitDestination,"searchPlaceholder":"Pretraži artikle"}})],1),_c('v-container',{staticClass:"pt-0"},[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.staffs),function(staff,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
              'ma-0',
              staff.status === 'OK'
                ? 'status-ok-exp'
                : ("" + (staff.status === 'PROCESSING'
                      ? 'status-processing-exp'
                      : 'status-failed-exp')) ],staticStyle:{"height":"48px !important"}},[_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"50px","max-width":"10%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Ime")]),_c('div',{ref:("hover-" + (staff.id)),refInFor:true,staticClass:"text-subtitle-1 one-liner-text",attrs:{"id":("hover-" + (staff.id))},on:{"mouseover":function($event){return _vm.hoverOver(("hover-" + (staff.id)), staff)},"mouseleave":function($event){staff[("hover-" + (staff.id))] = false}}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(staff.name))+" ")])]),_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"50px","max-width":"10%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Prezime")]),_c('div',[_vm._v(_vm._s(_vm.$options.filters.capitalize(staff.surname)))])]),_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"100px","max-width":"50%"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Kategorije")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.showStaffCategories(staff.categories))+" ")])]),_c('v-col',{class:[_vm.densityPadding]},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Status")]),_c('div',{class:[
                  staff.status === 'OK'
                    ? 'success--text'
                    : ("" + (staff.status === 'PROCESSING'
                          ? 'info--text'
                          : 'error--text')),
                  'text-center' ]},[_vm._v(" "+_vm._s(staff.status)+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"2","md":"1","lg":"1"}},[_c('div',{staticClass:"d-flex align-start justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":"","disabled":staff.status !== 'OK'},on:{"click":function($event){return _vm.openEditStaff(staff)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditStaff(staff)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi osoblje")])])],1)])],1),_c('v-divider')],1)}),1),_c('add-staff',{ref:"addStaff",on:{"refreshStaff":function($event){return _vm.refresh()}}}),_c('edit-staff',{ref:"editStaff",on:{"refreshStaff":function($event){return _vm.refresh()}}})],1):_vm._e(),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-results"},slot:"no-results"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }