
import { functions } from '@/plugins/firebase'
import axios from 'axios'

async function getReport (payload = {}) {
  var token = await getToken('getReportData')

  var url = '/f/getReportData'
  if (process.env.NODE_ENV === 'local') {
    url = process.env.VUE_APP_API_ROOT + 'getReportData'
  }

  var data = {
    report: payload.report,
    dateStart: payload.dateFrom,
    dateEnd: payload.dateTo,
    warehouseId: payload.warehouseId
  }

  if (payload.id !== '') {
    data.id = payload.id
  }

  if (payload.item !== '') {
    data.item = payload.item
  }

  if (payload.lastDocumentedID !== '') {
    data.lastDocumentId = payload.lastDocumentedID
  }

  try {
    const response = await axios.post(url, {
      data: data
    }, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })

    if (response) {
      return response
    }
  } catch (error) {
    return error.response
  }
}

async function getToken (functionName) {
  var getIdentityToken = functions.httpsCallable('getidentitytoken')

  try {
    const response = await getIdentityToken({
      function: process.env.VUE_APP_API_ROOT + functionName
    })

    if (response.data.identity_token) {
      return response.data.identity_token
    }

    return ''
  } catch (error) {
    console.error('error', error)
  }
}

export { getReport, getToken }
