<template>
  <div style="border-radius: 25px !important">
    <v-dialog
      v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      persistent
      max-width="40%"
      max-height="600"
      style="height: 50px"
    >
      <v-overlay
        v-if="loading"
        z-index="1000"
        style="height: 100%; width: 100%; opacity: 1 !important"
      >
        <v-progress-circular
          :size="100"
          :width="10"
          style="color: #04aa6d; z-index: 1000"
          indeterminate
          >Pričekajte</v-progress-circular
        >
      </v-overlay>
      <v-form>
        <v-card
          tile
          color="white"
          class="pb-3"
          style="border-radius: 25px !important"
        >
          <v-card-title
            class="d-flex"
            style="
              vertical-align: middle;
              background-color: #1577da;

              height: 58px;
              color: white;
            "
          >
            <span class="text-h5" style="font-weight: bold">Dodaj osoblje</span>
          </v-card-title>
          <v-card-text style="padding: 0; margin: 0; overflow-x: hidden">
            <v-row class="flex-wrap justify-center pr-0 pl-4">
              <v-col>
                <v-row class="pt-0 mt-2 pb-0 mb-0 pl-5">
                  <div
                    style="width: 45% !important"
                    class="pt-0 mt-0 pb-0 mb-0 mr-2"
                  >
                    <v-text-field
                      class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      hide-details
                      outlined
                      label="Ime"
                      v-model="name"
                      :rules="[rules.req]"
                    >
                    </v-text-field>
                  </div>
                  <div
                    style="width: 45% !important"
                    class="pt-0 mt-0 pb-0 mb-0"
                  >
                    <v-text-field
                      class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      outlined
                      hide-details
                      label="Prezime"
                      v-model="surname"
                      :rules="[rules.req]"
                    >
                    </v-text-field>
                  </div>
                </v-row>

                <v-row class="pt-0 mt-0 pb-0 mb-0 pl-5">
                  <div style="width: 45%" class="pt-0 mt-2 pb-0 mb-0">
                    <v-combobox
                      clearable
                      :items="availableCategories"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Kategorija"
                      v-model="category"
                      :search-input.sync="category"
                    >
                    </v-combobox>
                  </div>
                  <!-- <v-col cols="3" class="pt-0 mt-2 pb-0 mb-0">
                    <v-btn class="btn infoButton" @click="saveCategory()"
                      ><v-icon>mdi-content-save-check-outline</v-icon></v-btn
                    >
                  </v-col> -->
                </v-row>
                <!-- <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12" v-if="categories && categories.length > 0">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%" colspan="1">Br.</th>
                          <th width="80%" colspan="1">Naziv kategorije</th>
                          <th width="10%" colspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in categories" :key="ct">
                          <td
                            style="text-align: center"
                            width="10%"
                            colspan="1"
                          >
                            {{ key + 1 }}
                          </td>
                          <td
                            style="text-align: center"
                            width="80%"
                            colspan="1"
                          >
                            {{ ct }}
                          </td>
                          <td
                            style="text-align: center"
                            width="10%"
                            colspan="1"
                          >
                            <v-icon @click="removeCategory(key)"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex pt-3 mt-0 pr-6 justify-end">
            <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
            <v-btn
              :disabled="category && category !== '' ? false : true"
              id="success"
              class="btn successButton"
              @click="addStaff()"
            >
              Završi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    dialogOpen: false,
    name: '',
    surname: '',
    category: '',
    categories: [],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: [],
    loading: false,
    availableCategories: []
  }),
  mounted () {
    this.getAvailableCategories()
  },
  methods: {
    async getAvailableCategories () {
      this.availableCategories = []
      const snapshot = await df.doc(`workorder_categories/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.categories && document.categories.length > 0) {
          this.availableCategories = [...document.categories]
          this.availableCategories.sort((a, b) => a.localeCompare(b))
        }
      }
    },
    removeCategory (index) {
      this.categories.splice(index, 1)
    },
    async saveCategory () {
      var newCategories = [...this.availableCategories]
      newCategories.push(this.category)

      if (this.availableCategories.length === 0) {
        const docRef = df.doc(`workorder_categories/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            categories: newCategories
          })
      } else {
        df.doc(`workorder_categories/${state.getCurrentCompany().id}`)
          .update({
            categories: newCategories
          })
      }

      this.getAvailableCategories()
    },

    addCategory () {
      var temp = this.category
      this.categories.push(temp)
      this.category = ''
    },

    open () {
      this.dialogOpen = true
      this.submitting = false
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.name = ''
      this.surname = ''
      this.category = ''
      this.categories = []
      this.dialogOpen = false
    },
    addStaff () {
      this.loading = true

      if (!this.availableCategories.includes(this.category)) {
        this.saveCategory()
      }
      var workorderApi = functions.httpsCallable('workorderapi')

      var categories = [this.category]
      workorderApi({
        action: {
          operation: 'set',
          entity: 'staff',
          resource_id: '',
          params: {
            name: this.name,
            surname: this.surname,
            categories: categories,
            company_id: state.getCurrentCompany().id
          }
        }
      })
        .then((result) => {
          this.loading = false
          if (result.data.code === 200) {
            this.$emit('refreshStaff')
            this.showMsgBox({
              text: 'Uspješno dodavanje',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.resetInputs()
            this.dialogOpen = false
          } else {
            this.showMsgBox({
              text: 'Dogodila se greška, neuspješno dodavanje',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }
        })
    }
  }

}
</script>
