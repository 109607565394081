var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-container',{staticClass:"pt-0"},[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.fields),function(field,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
            'ma-0',
            field.status === 'OK'
              ? 'status-ok-exp'
              : ("" + (field.status === 'PROCESSING'
                ? 'status-processing-exp'
                : 'status-failed-exp')) ],staticStyle:{"height":"48px !important"}},[_c('div',{staticClass:"pl-3",class:[_vm.densityPadding],staticStyle:{"width":"15% !important"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Labela")]),_c('div',{ref:("hover-" + (field.id)),refInFor:true,staticClass:"text-subtitle-1 one-liner-text",attrs:{"id":("hover-" + (field.id))},on:{"mouseover":function($event){return _vm.hoverOver(("hover-" + (field.id)), field)},"mouseleave":function($event){field[("hover-" + (field.id))] = false}}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(field.label))+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"25% !important"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Tip")]),_c('div',[_vm._v(" "+_vm._s(_vm.showType(field.field_type))+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"12% !important"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Obavezno polje")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(field.mandatory ? "DA" : "NE")+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"15% !important"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v(" Zadana vrijednost ")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(field.default_value)+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"15% !important"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v(" Koristi se na printu ")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(field.printable ? "DA" : "NE")+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"8% !important"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Status")]),_c('div',{class:[
                field.status === 'OK'
                  ? 'success--text'
                  : ("" + (field.status === 'PROCESSING'
                    ? 'info--text'
                    : 'error--text')),
                'text-center' ]},[_vm._v(" "+_vm._s(field.status)+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"5% !important"}},[_c('div',{staticClass:"d-flex align-start justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":"","disabled":field.status !== 'OK'},on:{"click":function($event){return _vm.openEditField(field)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditField(field)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi parametar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":"","disabled":field.status !== 'OK'},on:{"click":function($event){return _vm.deleteField(field)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.deleteField(field)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Obriši parametar")])])],1)])]),_c('v-divider')],1)}),1),_c('add-field',{ref:"addField",on:{"refreshFields":function($event){return _vm.refresh()}}}),_c('edit-field',{ref:"editField",on:{"refreshFields":function($event){return _vm.refresh()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }