<template>
  <div>
    <v-container class="justify-center"
      :class="$vuetify.breakpoint.mobile ? 'contic' : 'justify-center'">
      <div style="
          background-color: #5767b6;
          height: 100px;
          padding: 20px;
          margin-bottom: 20px;
          box-shadow: 1px 1px 1px #f6f6f6;
        ">
        <v-row style="padding: 10px">
          <v-col cols="3">
            <v-img max-height="120"
              max-width="180"
              src="@/assets/logo-AI-kasa-negativ.png"
              @click="openAikasaWeb()">
            </v-img></v-col>
          <v-col cols="5">
            <div class="white--text font-weight-bold align-center justify-center">
              FISKALNA BLAGAJNA<br />
              Fiskalizirajte račune na svom telefonu, računalu ili tabletu
            </div>
          </v-col>
        </v-row>
      </div>
      <v-form ref="generateReportForm"
        @submit.prevent="submit">
        <v-row>
          <v-col cols="6">
            <v-combobox autofocus
              label="Odaberi tip dokumenta"
              v-model="docType"
              :items="documentTypes"
              outlined
              dense
              item-text="name"
              item-value="value"
              clearable
              :rules="[rules.req]"
              class="uppercased-input">
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item class="uppercased-input"
                  v-on="on"
                  v-bind="attrs">{{ item.name }}</v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card v-if="showForm">
          <v-card-title>
            <v-row class="pa-3 mb-2">
              <h3 class="text--secondary">GENERIRAJ PO SD OBRAZAC</h3>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="4"
                md="4"
                lg="4"
                cols="10">
                <v-text-field label="OIB"
                  v-model="oib"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col sm="4"
                md="4"
                lg="4"
                cols="10">
                <v-text-field label="IME I PREZIME"
                  v-model="firstLast"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col sm="4"
                md="4"
                lg="4"
                cols="10">
                <v-text-field label="ADRESA PREBIVALIŠTA / UOBIČAJENOG BORAVIŠTA"
                  v-model="adresaPrebivalista"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-card-text>
                <v-row class="pa-3">
                  <h3 class="text--secondary">II. PODACI O DJELATNOSTI</h3>
                  <v-spacer></v-spacer>
                </v-row>
              </v-card-text>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="NAZIV I VRSTA DJELATNOSTI"
                  v-model="nazivVrstaDjelatnosti"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="ADRESA OBAVLJANJA DJELATNOSTI"
                  v-model="adresaDjelatnosti"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row class="pa-3">
                <h3 class="text--secondary">
                  GRAD VUKOVAR I POTPOMOGNUTA PODRUČJA JEDINICA LOKALNE
                  SAMOUPRAVE I. SKUPINE I OTOCI I. SKUPINE
                </h3>
              </v-row>
            </v-card-text>
            <v-col class="py-0">
              <v-radio-group v-model="vuIslands"
                class="mt-0">
                <v-radio v-for="radio in radios"
                  :key="radio.value"
                  :value="radio.value"
                  :label="radio.name"></v-radio>
              </v-radio-group>
            </v-col>
            <v-row>
              <v-col>
                <v-card-text>
                  <v-row class="pa-3">
                    <h3 class="text--secondary">
                      RAZDOBLJE OBAVLJANJA DJELATNOSTI
                    </h3>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu v-model="fromMenu1"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatFrom1"
                      label="OD DATUMA"
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="from1"
                    no-title
                    scrollable
                    min="2024-01-01"
                    @change="changeMonths()"
                    :max="maxTo1()"
                    locale="hr-HR"
                    @input="fromMenu1 = false">
                  </v-date-picker>
                </v-menu>
                <v-menu v-model="fromMenu2"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatFrom2"
                      label="OD DATUMA"
                      dense
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @change="clearFrom2()"
                      clearable
                      v-on="on"
                      @input="fromMenu2 = false"></v-text-field>
                  </template>
                  <v-date-picker v-model="from2"
                    no-title
                    scrollable
                    :max="maxTo2()"
                    :min="minto1()"
                    @change="changeMonths()"
                    locale="hr-HR"
                    @input="fromMenu2 = false">
                  </v-date-picker>
                </v-menu>

                <v-menu v-model="fromMenu3"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatFrom3"
                      label="OD DATUMA"
                      dense
                      prepend-icon="mdi-calendar"
                      @change="clearFrom3()"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="from3"
                    no-title
                    scrollable
                    :max="maxTo3()"
                    :min="minto2()"
                    @change="changeText()"
                    locale="hr-HR"
                    @input="fromMenu3 = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu v-model="toMenu1"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatTo1"
                      label="DO DATUMA"
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="to1"
                    locale="hr-HR"
                    max="2030-12-31"
                    no-title
                    scrollable
                    @change="changeMonths()"
                    @input="toMenu1 = false">
                  </v-date-picker>
                </v-menu>
                <v-menu v-model="toMenu2"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatTo2"
                      label="DO DATUMA"
                      dense
                      prepend-icon="mdi-calendar"
                      @change="clearTo2()"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="to2"
                    locale="hr-HR"
                    :min="from2"
                    max="2030-12-31"
                    no-title
                    scrollable
                    @change="changeMonths()"
                    @input="toMenu2 = false">
                  </v-date-picker>
                </v-menu>
                <v-menu v-model="toMenu3"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatTo3"
                      label="DO DATUMA"
                      dense
                      clearable
                      @change="clearTo3()"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="to3"
                    locale="hr-HR"
                    no-title
                    :min="from2"
                    max="2030-12-31"
                    scrollable
                    @change="changeMonths()"
                    @input="toMenu3 = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row class="pa-3">
                <h3 class="text--secondary">
                  III. PODACI O OSTVARENIM PRIMICIMA OD POJEDINAČNE DJELATNOSTI
                </h3>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col sm="4"
                md="4"
                lg="4"
                cols="10">
                <money-input label="Primici naplaćeni u gotovini"
                  v-model="primiciGotovina"
                  :currency="currency"
                  ref="primiciGotovina"
                  @change="promjenaPrimitaka()"
                  outlined
                  dense>
                </money-input>
              </v-col>
              <v-col sm="4"
                md="4"
                lg="4"
                cols="10">
                <money-input label="Primici naplaćeni bezgotovinskim putem"
                  v-model="primiciBezgotovinski"
                  :currency="currency"
                  ref="primiciBezgotovinski"
                  @change="promjenaPrimitaka()"
                  outlined
                  dense>
                </money-input>
              </v-col>
              <v-col sm="4"
                md="4"
                lg="4"
                cols="10">
                <money-input label="Ukupno naplaćeni primici"
                  v-model="ukupniPrimici"
                  :currency="currency"
                  ref="ukupniPrimici"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row class="pa-3">
                <h3 class="text--secondary">
                  IV. GODIŠNJI PAUŠALNI DOHODAK OD POJEDINAČNE DJELATNOSTI /
                  BROJ MJESECI OBAVLJANJA DJELATNOSTI
                </h3>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col>
                <money-input label=""
                  v-model="dohodakSamostalni"
                  :currency="currency"
                  ref="dohodakSamostalni"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
              <v-col>
                <v-text-field label=""
                  v-model="mjeseciSamostalni"
                  ref="mjeseciSamostalni"
                  outlined
                  suffix="mjeseci"
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row class="pa-3">
                <h3 class="text--secondary">
                  V. GODIŠNJI PAUŠALNI DOHODAK OD ZAJEDNIČKE DJELATNOSTI / BROJ
                  MJESECI OBAVLJANJA DJELATNOSTI
                </h3>
              </v-row>
              <v-spacer></v-spacer>
            </v-card-text>
            <v-row>
              <v-col>
                <money-input label=""
                  v-model="dohodakZajednicki"
                  :currency="currency"
                  ref="dohodakZajednicki"
                  @change="promjenaZajednickogDohotka"
                  outlined
                  dense>
                </money-input>
              </v-col>
              <v-col>
                <v-text-field label=""
                  v-model="mjeseciZajednicki"
                  suffix="mjeseci"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card-text>
                  <h3 class="text--secondary">
                    VI. UKUPNI GODIŠNJI PAUŠALNI DOHODAK
                  </h3>
                </v-card-text>
              </v-col>
              <v-col>
                <money-input label=""
                  v-model="ukupniDohodak"
                  :currency="currency"
                  ref="ukupniDohodak"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card-text>
                  <h3 class="text--secondary">
                    VII. OBRAČUN PAUŠALNOG POREZA NA DOHODAK
                  </h3>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"
                sm="1"
                md="1"
                lg="1">
                <v-card-text>
                  <h3 class="text--secondary">1.</h3>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-col>
              <v-col cols="9"
                sm="8"
                md="8"
                lg="8">
                <v-card-text>
                  <h3 class="text--secondary">
                    IZNOS OBVEZE PAUŠALNOG POREZA NA DOHODAK
                  </h3>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-col>
              <v-col cols="10"
                sm="2"
                md="2"
                lg="2">
                <money-input label=""
                  v-model="porezNaDohodak"
                  :currency="currency"
                  ref="porezNaDohodak"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"
                sm="1"
                md="1"
                lg="1">
                <v-card-text>
                  <h3 class="text--secondary">2.</h3>
                </v-card-text>
              </v-col>
              <v-col cols="9"
                sm="8"
                md="8"
                lg="8">
                <v-card-text>
                  <h3 class="text--secondary">
                    UMANJENJE PAUŠALNOG POREZA NA DOHODAK ZA PODRUČJE GRADA
                    VUKOVARA I POTPOMOGNUTIH PODRUČJA JEDINICA LOKALNO
                    SAMOUPRAVI I. SKUPINE I OTOCI I. SKUPINE
                  </h3>
                </v-card-text>
              </v-col>
              <v-col cols="10"
                sm="2"
                md="2"
                lg="2">
                <money-input label=""
                  v-model="umanjenjePoreza"
                  :currency="currency"
                  ref="umanjenjePoreza"
                  @change="umanjenjePorezaPromjena()"
                  outlined
                  dense>
                </money-input>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"
                sm="1"
                md="1"
                lg="1">
                <v-card-text>
                  <h3 class="text--secondary">3.</h3>
                </v-card-text>
              </v-col>
              <v-col cols="9"
                sm="8"
                md="8"
                lg="8">
                <v-card-text>
                  <h3 class="text--secondary">
                    UKUPNA OBVEZA PAUŠALNOG POREZA NA DOHODAK NA
                    DOHODAK NAKON UMANJENJA
                  </h3>
                </v-card-text>
              </v-col>
              <v-col cols="10"
                sm="2"
                md="2"
                lg="2">
                <money-input label=""
                  v-model="ukupniPorezPrirezNakonUmanjenja"
                  :currency="currency"
                  ref="ukupniPorezPrirezNakonUmanjenja"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"
                sm="1"
                md="1"
                lg="1">
                <v-card-text>
                  <h3 class="text--secondary">4.</h3>
                </v-card-text>
              </v-col>
              <v-col cols="9"
                sm="8"
                md="8"
                lg="8">
                <v-card-text>
                  <h3 class="text--secondary">
                    UKUPNO UPLAĆENI PAUŠALNI POREZ NA DOHODAK
                  </h3>
                </v-card-text>
              </v-col>
              <v-col cols="10"
                sm="2"
                md="2"
                lg="2">
                <money-input label=""
                  v-model="uplaceniPorezPrirez"
                  :currency="currency"
                  ref="uplaceniPorezPrirez"
                  @change="promjenaPlacenog()"
                  outlined
                  dense>
                </money-input>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"
                sm="1"
                md="1"
                lg="1">
                <v-card-text>
                  <h3 class="text--secondary">5.</h3>
                </v-card-text>
              </v-col>
              <v-col cols="9"
                sm="8"
                md="8"
                lg="8">
                <v-card-text>
                  <h3 class="text--secondary">RAZLIKA ZA UPLATU/POVRAT</h3>
                </v-card-text>
              </v-col>
              <v-col cols="10"
                sm="2"
                md="2"
                lg="2">
                <money-input label=""
                  v-model="razlikaUplataPovrat"
                  :currency="currency"
                  ref="razlikaUplataPovrat"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
              <v-col> </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"
                sm="1"
                md="1"
                lg="1">
                <v-card-text>
                  <h3 class="text--secondary">6.</h3>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-col>
              <v-col cols="9"
                sm="8"
                md="8"
                lg="8">
                <v-card-text>
                  <h3 class="text--secondary">
                    IZNOS MJESEČNOG PAUŠALNOG POREZA NA DOHODAK
                  </h3>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-col>
              <v-col cols="10"
                sm="2"
                md="2"
                lg="2">
                <money-input label=""
                  v-model="porezPrirezMjesecni"
                  :currency="currency"
                  ref="porezPrirezMjesecni"
                  outlined
                  disabled
                  dense>
                </money-input>
              </v-col>
              <v-col> </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center pb-5">
            <v-btn class="white--text okButton"
              height="45"
              :disabled="submitting"
              :loading="submitting"
              type="submittanje">
              Generiraj izvještaj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-overlay v-show="loading"
        absolute
        z-index="10">
        <v-progress-circular indeterminate
          size="64"
          color="primary"></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
import applicationSettings from '@/mixins/applicationSettings'
import { generatePosd } from '@/api/default'
import { df } from '@/plugins/firebase'

import rules from '@/plugins/rules'
import MoneyInput from '@/components/MoneyInput'

export default {
  name: 'Reports',
  mixins: [applicationSettings],
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { MoneyInput },
  data: function () {
    return {
      visible: false,
      lastVisible: '',
      submitting: false,
      loading: false,
      companies: [],
      company: undefined,
      oib: '',
      firstLast: '',
      adresaPrebivalista: '',
      entrepreneurName: '',
      nazivVrstaDjelatnosti: '',
      adresaDjelatnosti: '',
      showForm: true,
      vuIslands: false,
      from1: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('sv-SE').substr(0, 10),
      fromMenu1: false,
      from2: '',
      fromMenu2: false,
      from3: '',
      fromMenu3: false,
      to1: new Date().toISOString().substr(0, 10),
      toMenu1: false,
      to2: '',
      toMenu2: false,
      to3: '',
      toMenu3: false,
      totalFromMenu: false,
      totalFrom: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString('sv-SE').substr(0, 10),
      totalToMenu: false,
      totalTo: new Date(new Date().getFullYear(), 11, 31).toLocaleDateString('sv-SE').substr(0, 10),
      form: '',
      docType: { name: 'PDF', value: 'pdf' },
      ukupniPrimici: 0,
      primiciGotovina: 0,
      primiciBezgotovinski: 0,
      mjeseciSamostalni: 0,
      dohodakSamostalni: 0,
      mjeseciZajednicki: 0,
      dohodakZajednicki: 0,
      ukupniDohodak: 0,
      porezNaDohodak: 0,
      prirezPostotak: 0,
      prirezIznos: 0,
      ukupniPorezPrirez: 0,
      umanjenjePoreza: 0,
      ukupniPorezPrirezNakonUmanjenja: 0,
      uplaceniPorezPrirez: 0,
      razlikaUplataPovrat: 0,
      porezPrirezMjesecni: 0,
      taxPercent: 0.12,
      locationAddress: '',
      locationName: '',
      activityCode: '',
      currency: 'EUR',
      posdClasses: [],
      receipts: [],
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        arrReq: rules.arrReq(),
        validPass: rules.validPass,
        money: rules.money()
      }
    }
  },
  mounted () {
    this.getPosdClasses()
    this.changeMonths()
  },
  watch: {
    search () {
      this.lastVisible = ''
    }
  },
  computed: {
    radios () {
      return [
        { name: 'Da', value: true },
        { name: 'Ne', value: false }
      ]
    },
    documentTypes () {
      return [
        { name: 'PDF', value: 'pdf' },
        { name: 'XLSX', value: 'xlsx' }
      ]
    },
    formatFrom1 () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.from1))
    },
    formatFrom2 () {
      if (this.from2 !== '') {
        return new Intl.DateTimeFormat('hr-HR', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(this.from2))
      } else {
        return ''
      }
    },
    formatFrom3 () {
      if (this.from3 !== '') {
        return new Intl.DateTimeFormat('hr-HR', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(this.from3))
      } else {
        return ''
      }
    },
    formatTo1 () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.to1))
    },
    formatTo2 () {
      if (this.to2 !== '') {
        return new Intl.DateTimeFormat('hr-HR', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(this.to2))
      } else {
        return ''
      }
    },
    formatTo3 () {
      if (this.to3 !== '') {
        return new Intl.DateTimeFormat('hr-HR', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(this.to3))
      } else {
        return ''
      }
    },
    formatTotalFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.totalFrom))
    },
    formatTotalTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.totalTo))
    },

    totalFrom__datetime () {
      return parseInt(
        (new Date(`${this.totalFrom}`).getTime() / 1000).toFixed(0)
      )
    },
    totalTo__datetime () {
      return parseInt(
        (new Date(`${this.totalTo}T23:59:00`).getTime() / 1000).toFixed(0)
      )
    },
    from1__datetime () {
      return parseInt(
        (new Date(`${this.from1}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    from2__datetime () {
      return parseInt(
        (new Date(`${this.from2}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },

    from3__datetime () {
      return parseInt(
        (new Date(`${this.from3}:00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    to1__datetime () {
      return parseInt(
        (new Date(`${this.to1}:00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    to2__datetime () {
      return parseInt(
        (new Date(`${this.to2}:00:00:00`).getTime() / 1000).toFixed(0)
      )
    },

    to3__datetime () {
      return parseInt(
        (new Date(`${this.to3}:00:00:00`).getTime() / 1000).toFixed(0)
      )
    }
  },
  methods: {
    openAikasaWeb () {
      window.location.href = 'https://aikasa.app'
    },
    clearFrom2 () {
      this.from2 = ''
      this.changeMonths()
    },
    clearFrom3 () {
      this.from3 = ''
      this.changeMonths()
    },
    clearTo2 () {
      this.to2 = ''
      this.changeMonths()
    },
    clearTo3 () {
      this.to3 = ''
      this.changeMonths()
    },
    minto1 () {
      if (this.to1 === '' || this.to1 === undefined) {
      } else {
        return '2023-01-01'
      }
    },
    minto2 () {
      if (this.to2 !== '') {
        const following = new Date(`${this.to2}:00:00:00`)
        following.setUTCDate(following.getDate() + 1)
        return following.toISOString().substring(0, 10)
      } else {
        if (this.to1 === '') {
        } else {
          return '2023-01-01'
        }
      }
    },
    minTo3 () {
      if (this.to3 !== '') {
        return this.toMenu3
      } else if (this.to2 !== '') {
        return this.to2
      } else if (this.to1 !== '') {
        return this.to1
      } else {
        return '2023-01-01'
      }
    },
    maxTo1 () {
      if (this.to1 !== '') {
        return this.to1
      } else {
        return '2030-12-31'
      }
    },
    maxTo2 () {
      if (this.to2 !== '') {
        return this.to2
      } else {
        return '2030-12-31'
      }
    },
    maxTo3 () {
      if (this.to3 !== '') {
        return this.to3
      } else {
        return '2030-12-31'
      }
    },
    checkTableValues (ukupniPrimici, ukupniMjeseci) {
      let returnVal = 0
      let payments = ukupniPrimici
      if (ukupniMjeseci !== 12) {
        payments = (ukupniPrimici / ukupniMjeseci) * 12
      }

      this.posdClasses.forEach((classes, i) => {
        if (payments >= classes.lower && payments <= classes.upper && i < 4) {
          returnVal = classes.total
          if (ukupniMjeseci !== 12) {
            returnVal = Math.round((returnVal / 12) * ukupniMjeseci)
          }
        }

        if (payments >= classes.lower && i === 4) {
          returnVal = classes.total
          if (ukupniMjeseci !== 12) {
            returnVal = Math.round((returnVal / 12) * ukupniMjeseci)
          }
        }
      })

      return returnVal
    },
    promjenaPrimitaka () {
      const that = this

      const primiciGotovina = parseFloat(
        (
          that.$refs.primiciGotovina.currencyToFloat(
            that.primiciGotovina
          ) * 100
        ).toFixed(2)
      )

      const primiciBezgotovinski = parseFloat(
        (
          that.$refs.primiciBezgotovinski.currencyToFloat(
            that.primiciBezgotovinski
          ) * 100
        ).toFixed(2)
      )

      that.ukupniPrimici = primiciGotovina + primiciBezgotovinski

      that.$refs.ukupniPrimici.$el.getElementsByTagName('input')[0].value =
        that.ukupniPrimici

      let totalMonths = 0
      if (that.from1 !== '' && that.to1 !== '') {
        const total = this.calculateMonths(that.from1, that.to1)
        totalMonths += total
      }
      if (that.from2 !== '' && that.to2 !== '') {
        const total = this.calculateMonths(that.from2, that.to2)
        totalMonths += total
      }
      if (that.from3 !== '' && that.to3 !== '') {
        const total = this.calculateMonths(that.from3, that.to3)
        totalMonths += total
      }

      that.mjeseciSamostalni = totalMonths

      that.$refs.mjeseciSamostalni.$el.getElementsByTagName('input')[0].value =
        that.mjeseciSamostalni

      that.dohodakSamostalni = this.checkTableValues(that.ukupniPrimici, totalMonths)

      that.$refs.dohodakSamostalni.$el.getElementsByTagName(
        'input'
      )[0].value = that.dohodakSamostalni

      const dohodakZajednicki = parseFloat(
        (
          this.$refs.dohodakZajednicki.currencyToFloat(that.dohodakZajednicki) *
          100
        ).toFixed(2)
      )

      that.ukupniDohodak = that.dohodakSamostalni + dohodakZajednicki

      that.$refs.ukupniDohodak.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniDohodak

      that.porezNaDohodak = that.ukupniDohodak * this.taxPercent

      that.porezNaDohodak = Math.round(that.porezNaDohodak)

      that.$refs.porezNaDohodak.$el.getElementsByTagName('input')[0].value =
        parseInt(that.porezNaDohodak)

      that.ukupniPorezPrirez = that.porezNaDohodak

      that.ukupniPorezPrirezNakonUmanjenja = that.ukupniPorezPrirez
      that.$refs.ukupniPorezPrirezNakonUmanjenja.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniPorezPrirezNakonUmanjenja

      that.razlikaUplataPovrat = that.ukupniPorezPrirez
      that.$refs.razlikaUplataPovrat.$el.getElementsByTagName(
        'input'
      )[0].value = that.razlikaUplataPovrat

      const ukupniDohodak = parseFloat(
        (
          this.$refs.ukupniDohodak.currencyToFloat(that.ukupniDohodak)
        ).toFixed(2)
      )

      var t = Math.round(ukupniDohodak * this.taxPercent)
      that.porezPrirezMjesecni = Math.round(t / that.mjeseciSamostalni)

      that.$refs.porezPrirezMjesecni.$el.getElementsByTagName(
        'input'
      )[0].value = that.porezPrirezMjesecni
    },
    promjenaZajednickogDohotka () {
      const that = this

      const dohodakSamostalni = parseFloat(
        (
          this.$refs.dohodakSamostalni.currencyToFloat(that.dohodakSamostalni) *
          100
        ).toFixed(2)
      )

      const dohodakZajednicki = parseFloat(
        (
          this.$refs.dohodakZajednicki.currencyToFloat(that.dohodakZajednicki) *
          100
        ).toFixed(2)
      )

      that.ukupniDohodak = dohodakSamostalni + dohodakZajednicki

      that.$refs.ukupniDohodak.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniDohodak

      that.porezNaDohodak = that.ukupniDohodak * this.taxPercent

      that.porezNaDohodak = Math.round(that.porezNaDohodak)

      that.$refs.porezNaDohodak.$el.getElementsByTagName('input')[0].value =
        parseInt(that.porezNaDohodak)

      that.ukupniPorezPrirez = that.porezNaDohodak

      that.ukupniPorezPrirezNakonUmanjenja = that.ukupniPorezPrirez
      that.$refs.ukupniPorezPrirezNakonUmanjenja.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniPorezPrirezNakonUmanjenja

      that.razlikaUplataPovrat = that.ukupniPorezPrirez
      that.$refs.razlikaUplataPovrat.$el.getElementsByTagName(
        'input'
      )[0].value = that.razlikaUplataPovrat

      const ukupniDohodak = parseFloat(
        (
          this.$refs.ukupniDohodak.currencyToFloat(that.ukupniDohodak)
        ).toFixed(2)
      )

      var t = Math.round(ukupniDohodak * this.taxPercent)
      that.porezPrirezMjesecni = Math.round(t / that.mjeseciSamostalni)

      that.$refs.porezPrirezMjesecni.$el.getElementsByTagName(
        'input'
      )[0].value = that.porezPrirezMjesecni
    },
    ukupniPorezPrirezPromjena () {
      const that = this
      const ukupniPorezPrirez = parseFloat(
        (
          this.$refs.ukupniPorezPrirez.currencyToFloat(
            this.ukupniPorezPrirez
          ) * 100
        ).toFixed(2)
      )

      that.ukupniPorezPrirezNakonUmanjenja = ukupniPorezPrirez
      that.$refs.ukupniPorezPrirezNakonUmanjenja.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniPorezPrirezNakonUmanjenja

      that.razlikaUplataPovrat = that.ukupniPorezPrirez
      that.$refs.razlikaUplataPovrat.$el.getElementsByTagName(
        'input'
      )[0].value = that.razlikaUplataPovrat

      const ukupniDohodak = parseFloat(
        (
          this.$refs.ukupniDohodak.currencyToFloat(that.ukupniDohodak) *
          100
        ).toFixed(2)
      )

      var t = Math.round(ukupniDohodak * this.taxPercent)
      that.porezPrirezMjesecni = Math.round(t / that.mjeseciSamostalni)

      that.$refs.porezPrirezMjesecni.$el.getElementsByTagName('input')[0].value =
        that.porezPrirezMjesecni
    },
    promjenaPlacenog () {
      const ukupniPorezPrirezNakonUmanjenja = parseFloat(
        (
          this.$refs.ukupniPorezPrirezNakonUmanjenja.currencyToFloat(
            this.ukupniPorezPrirezNakonUmanjenja
          ) * 100
        ).toFixed(2)
      )

      const uplaceniPorezPrirez = parseFloat(
        (
          this.$refs.uplaceniPorezPrirez.currencyToFloat(this.uplaceniPorezPrirez) * 100
        ).toFixed(2)
      )

      this.razlikaUplataPovrat = ukupniPorezPrirezNakonUmanjenja - uplaceniPorezPrirez
      this.$refs.razlikaUplataPovrat.$el.getElementsByTagName(
        'input'
      )[0].value = this.razlikaUplataPovrat
    },
    umanjenjePorezaPromjena () {
      const ukupniPorezPrirez = parseFloat(
        (
          this.$refs.ukupniPorezPrirez.currencyToFloat(
            this.ukupniPorezPrirez
          ) * 100
        ).toFixed(2)
      )

      const umanjenjePoreza = parseFloat(
        (
          this.$refs.umanjenjePoreza.currencyToFloat(this.umanjenjePoreza) *
          100
        ).toFixed(2)
      )

      this.ukupniPorezPrirezNakonUmanjenja = ukupniPorezPrirez - umanjenjePoreza

      this.$refs.ukupniPorezPrirezNakonUmanjenja.$el.getElementsByTagName(
        'input'
      )[0].value = this.ukupniPorezPrirezNakonUmanjenja

      this.razlikaUplataPovrat = this.ukupniPorezPrirezNakonUmanjenja

      if (this.uplaceniPorezPrirez !== 0) {
        const uplaceniPorezPrirez = parseFloat(
          (
            this.$refs.uplaceniPorezPrirez.currencyToFloat(this.uplaceniPorezPrirez) * 100
          ).toFixed(2)
        )

        this.razlikaUplataPovrat =
          this.ukupniPorezPrirezNakonUmanjenja - uplaceniPorezPrirez
      }

      this.$refs.razlikaUplataPovrat.$el.getElementsByTagName(
        'input'
      )[0].value = this.razlikaUplataPovrat

      const ukupniDohodak = parseFloat(
        (
          this.$refs.ukupniDohodak.currencyToFloat(this.ukupniDohodak) *
          100
        ).toFixed(2)
      )

      var t = Math.round(ukupniDohodak * this.taxPercent)
      this.porezPrirezMjesecni = Math.round(t / this.mjeseciSamostalni)

      this.$refs.porezPrirezMjesecni.$el.getElementsByTagName('input')[0].value =
        this.porezPrirezMjesecni
    },

    calculateMonths (from, to) {
      const from1 = new Date(from)
      const to1 = new Date(to)

      let monthsDiff = (to1.getMonth() + 1) - (from1.getMonth() + 1)

      if (from1.getDate() === 1 && to1.getDate() >= 1) {
        monthsDiff += 1
      }

      return monthsDiff
    },
    changeMonths () {
      const that = this
      let totalMonths = 0
      if (that.from1 !== '' && that.to1 !== '') {
        const total = this.calculateMonths(that.from1, that.to1)
        totalMonths += total
      }
      if (that.from2 !== '' && that.to2 !== '') {
        const total = this.calculateMonths(that.from2, that.to2)
        totalMonths += total
      }
      if (that.from3 !== '' && that.to3 !== '') {
        const total = this.calculateMonths(that.from3, that.to3)
        totalMonths += total
      }

      if (totalMonths === 0) {
        totalMonths = 1
      }

      that.mjeseciSamostalni = totalMonths

      that.$refs.mjeseciSamostalni.$el.getElementsByTagName('input')[0].value =
        that.mjeseciSamostalni

      const primiciGotovina = parseFloat(
        (
          that.$refs.primiciGotovina.currencyToFloat(
            that.primiciGotovina
          ) * 100
        ).toFixed(2)
      )

      const primiciBezgotovinski = parseFloat(
        (
          that.$refs.primiciBezgotovinski.currencyToFloat(
            that.primiciBezgotovinski
          ) * 100
        ).toFixed(2)
      )

      that.ukupniPrimici = primiciGotovina + primiciBezgotovinski

      that.$refs.ukupniPrimici.$el.getElementsByTagName('input')[0].value =
        that.ukupniPrimici

      that.dohodakSamostalni = this.checkTableValues(that.ukupniPrimici, totalMonths)

      that.$refs.dohodakSamostalni.$el.getElementsByTagName(
        'input'
      )[0].value = that.dohodakSamostalni

      const dohodakZajednicki = parseFloat(
        (
          this.$refs.dohodakZajednicki.currencyToFloat(that.dohodakZajednicki) *
          100
        ).toFixed(2)
      )

      that.ukupniDohodak = that.dohodakSamostalni + dohodakZajednicki

      that.$refs.ukupniDohodak.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniDohodak

      that.porezNaDohodak = that.ukupniDohodak * this.taxPercent

      that.porezNaDohodak = Math.round(that.porezNaDohodak)

      that.$refs.porezNaDohodak.$el.getElementsByTagName('input')[0].value =
        parseInt(that.porezNaDohodak)

      that.ukupniPorezPrirez = that.porezNaDohodak

      that.ukupniPorezPrirezNakonUmanjenja = that.ukupniPorezPrirez
      that.$refs.ukupniPorezPrirezNakonUmanjenja.$el.getElementsByTagName(
        'input'
      )[0].value = that.ukupniPorezPrirezNakonUmanjenja

      that.razlikaUplataPovrat = that.ukupniPorezPrirez
      that.$refs.razlikaUplataPovrat.$el.getElementsByTagName(
        'input'
      )[0].value = that.razlikaUplataPovrat

      const ukupniDohodak = parseFloat(
        (
          this.$refs.ukupniDohodak.currencyToFloat(that.ukupniDohodak)
        ).toFixed(2)
      )

      var t = Math.round(ukupniDohodak * this.taxPercent)
      this.porezPrirezMjesecni = Math.round(t / this.mjeseciSamostalni)

      that.$refs.porezPrirezMjesecni.$el.getElementsByTagName(
        'input'
      )[0].value = that.porezPrirezMjesecni
    },
    async getPosdClasses () {
      const classesConfig = await df.doc('project_constants/posd').get()
      if (!classesConfig?.data()) return

      if (classesConfig.data().classes) {
        this.posdClasses = classesConfig.data().classes
      }
    },
    async submit () {
      if (!this.$refs.generateReportForm.validate()) return

      this.showLoader()
      const cashPay = parseFloat(
        (
          this.$refs.primiciGotovina.currencyToFloat(this.primiciGotovina) * 100
        ).toFixed(2)
      )

      const cashlessPay = parseFloat(
        (
          this.$refs.primiciBezgotovinski.currencyToFloat(this.primiciBezgotovinski) *
          100
        ).toFixed(2)
      )

      const porezNaDohodak = parseFloat(
        (
          this.$refs.porezNaDohodak.currencyToFloat(this.porezNaDohodak) * 100
        ).toFixed(2)
      )

      const umanjenjePoreza = parseFloat(
        (
          this.$refs.umanjenjePoreza.currencyToFloat(
            this.umanjenjePoreza
          ) * 100
        ).toFixed(2)
      )

      const uplaceniPorezPrirez = parseFloat(
        (
          this.$refs.uplaceniPorezPrirez.currencyToFloat(this.uplaceniPorezPrirez) * 100
        ).toFixed(2)
      )

      const ukupniPorezPrirezNakonUmanjenja = parseFloat(
        (
          this.$refs.ukupniPorezPrirezNakonUmanjenja.currencyToFloat(this.ukupniPorezPrirezNakonUmanjenja) * 100
        ).toFixed(2)
      )

      const ukupniPorezPrirez = parseFloat(this.ukupniPorezPrirez)

      const ukupniPrimici = parseFloat(
        (
          this.$refs.ukupniPrimici.currencyToFloat(this.ukupniPrimici) * 100
        ).toFixed(2)
      )

      const dohodakSamostalni = parseFloat(
        (
          this.$refs.dohodakSamostalni.currencyToFloat(this.dohodakSamostalni) * 100
        ).toFixed(2)
      )

      const dohodakZajednicki = parseFloat(
        (
          this.$refs.dohodakZajednicki.currencyToFloat(this.dohodakZajednicki) * 100
        ).toFixed(2)
      )

      const ukupniDohodak = parseFloat(
        (
          this.$refs.ukupniDohodak.currencyToFloat(this.ukupniDohodak) * 100
        ).toFixed(2)
      )

      const porezPrirezMjesecni = parseFloat(
        (
          this.$refs.porezPrirezMjesecni.currencyToFloat(this.porezPrirezMjesecni) * 100
        ).toFixed(2)
      )

      const period = []

      period.push({ from: this.from1__datetime, to: this.to1__datetime })

      if (this.to2 !== '' && this.from2 !== '') {
        period.push({
          from: this.from2__datetime,
          to: this.to2__datetime
        })
      }

      if (this.to3 !== '' && this.from3 !== '') {
        period.push({
          from: this.from3__datetime,
          to: this.to3__datetime
        })
      }

      try {
        const resp = await generatePosd({
          action: {
            operation: 'get',
            entity: 'posd_form',
            params: {
              date_from: this.totalFrom__datetime,
              date_to: this.totalTo__datetime,
              document_type: this.docType.value,
              name_of_activity: this.nazivVrstaDjelatnosti
                ? this.nazivVrstaDjelatnosti
                : '',
              entrepreneur_name: this.firstLast ? this.firstLast : '',
              residence_address: this.adresaPrebivalista
                ? this.adresaPrebivalista
                : '',
              entrepreneur_oib: this.oib ? this.oib : '',
              activity_address: this.adresaDjelatnosti
                ? this.adresaDjelatnosti
                : '',
              activity_period: period,
              cash_payments: cashPay || 0,
              cashless_payments: cashlessPay || 0,
              all_payments: ukupniPrimici,
              total_payments_solo: dohodakSamostalni,
              total_months_solo: this.mjeseciSamostalni,
              total_payments_together: dohodakZajednicki,
              total_months_together: parseInt(this.mjeseciZajednicki),
              total_income: ukupniDohodak,
              income_tax: porezNaDohodak || 0,
              super_tax_percent: 0,
              super_tax_amount: 0,
              income_tax_supertax: ukupniPorezPrirez,
              tax_reduction_vu_islands: umanjenjePoreza || 0,
              income_tax_supertax_reduction: ukupniPorezPrirezNakonUmanjenja,
              payed_lump_sum_tax: uplaceniPorezPrirez || 0,
              total_months: this.mjeseciSamostalni ? this.mjeseciSamostalni : 0,
              tax_supertax_monthly: porezPrirezMjesecni
            }
          }
        })
        if (resp.data && resp.data.header) {
          if (resp.data.header.code !== 200) {
            this.hideLoader()
            this.error.message = resp.data.header.error
            this.showMsgBox({
              text: resp.data.header.error,
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          } else {
            let linkSource = ''
            let fileName = ''
            this.hideLoader()

            fileName = `report-posd.${new Date().getTime()}`
            if (this.docType.value === 'pdf') {
              linkSource = `data:application/pdf;base64,${resp.data.document
                }`
              fileName = `${fileName}.pdf`
            }

            if (this.docType.value === 'xlsx') {
              linkSource = `data:application/vnd.ms-excel;base64,${resp.data.document
                }`
              fileName = `${fileName}.xlsx`
            }

            const downloadLink = document.createElement('a')
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
            this.$emit('onFinish')
          }
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="css" scoped>
@media screen and (max-width: 600px) {
  .container {
    max-width: 80% !important;
    padding: 0 !important;
  }
}

.contic {
  width: 85% !important;
  margin-right: 0px !important;
  margin-left: 16% !important;
}

/* .main-div {
  max-width: 15% important;
  padding: 0 !important;

} */
</style>
