
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
export default {
  name: 'pointOfSale',
  path: '/companies/:companyId/locations/:locationId',
  beforeEnter: async (to, from, next) => {
    if (to.params.companyId === state.getCurrentCompany().id && to.params.locationId === state.getPointOfSale().id) {
      next()
    } else {
      const companies = await df.doc(`user_companies/${auth.currentUser.uid}`).get()
      if (
        !companies ||
        !companies.exists ||
        !companies.data() ||
        Object.keys(companies.data().companies).length < 1 ||
        !companies.data().companies[to.params.companyId] ||
        companies.data().companies[to.params.companyId].status !== 'OK'
      ) {
        next({ name: 'catchall' })
        return
      }
      const company = companies.data().companies[to.params.companyId]
      const pointsOfSale = await df.doc(`user_locations/${auth.currentUser.uid}.${company.id}`).get()
      if (
        !pointsOfSale ||
        !pointsOfSale.data() ||
        !pointsOfSale.data().locations ||
        Object.keys(pointsOfSale.data().locations).length < 1 ||
        !pointsOfSale.data().locations[to.params.locationId] ||
        pointsOfSale.data().locations[to.params.locationId].status !== 'OK' ||
        Object.keys(pointsOfSale.data().locations).map(key => {
          return pointsOfSale.data().locations[key]
        })[0].resource_status !== 'ACTIVE'
      ) {
        next({ name: 'catchall' })
        return
      }
      const pointOfSale = pointsOfSale.data().locations[to.params.locationId]
      state.setCurrentCompany(company)
      state.setAppTitle(company.name)
      state.setPointOfSale(pointOfSale)
      next()
    }
  },
  redirect: (to) => {
    if (to.path === `/companies/${to.params.companyId}/locations/${to.params.locationId}`) {
      return `/companies/${to.params.companyId}/locations/${to.params.locationId}/registers`
    } else {
      return to.path
    }
  },
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "CashRegisters" */ '@/modules/point-of-sale/PointOfSale'),
  children: [
    {
      path: '/companies/:companyId/locations/:locationId/registers',
      name: 'pointOfSale.cashRegisters',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.registers',
        addItemText: '$vuetify.pointOfSale.addRegisterBtn',
        addItemAction: 'openAddRegisterForm',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "PriceList" */ '@/modules/point-of-sale/components/cash-registers/CashRegisters')
    },
    {
      path: '/companies/:companyId/locations/:locationId/price-list',
      name: 'pointOfSale.priceList',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.priceList',
        addItemText: '$vuetify.pointOfSale.addPricelistItemBtn',
        addItemAction: 'openAddPricelistItemForm',
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "PriceList" */ '@/modules/point-of-sale/components/price-list/PriceList')
    },
    {
      path: '/companies/:companyId/locations/:locationId/warehouse',
      name: 'pointOfSale.warehouse',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.warehouse',
        addItemText: '$vuetify.pointOfSale.addWarehouseItemBtn',
        addItemAction: 'openAddWarehouseItemForm',
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/Warehouse')
    },
    {
      path: '/companies/:companyId/locations/:locationId/items',
      name: 'pointOfSale.items',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.lager',
        addItemText: '$vuetify.pointOfSale.addLagerItemBtn',
        addItemAction: 'openaddItemForm',
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/items/lager',
      name: 'pointOfSale.items.lager',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.lager',
        addItemText: '$vuetify.pointOfSale.addLagerItemBtn',
        addItemAction: 'openaddItemForm',
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/lager/Lager')
    },
    {
      path: '/companies/:companyId/locations/:locationId/items/upload',
      name: 'pointOfSale.items.upload',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/lager/UploadItems'),
      meta: {
        title: '$vuetify.uploadItems.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/items/discount',
      name: 'pointOfSale.items.discount',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/discount/Discount'),
      meta: {
        title: '$vuetify.discount.titleText',
        addItemText: '$vuetify.company.addDiscountBtn',
        addItemAction: 'openaddDiscount',
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/items/categories',
      name: 'pointOfSale.items.categories',
      meta: {
        title: '$vuetify.companyTabs.categories',
        addItemText: '$vuetify.pointOfSale.addCategory',
        addItemAction: 'openAddCategoryForm',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Categories" */ '@/modules/company/components/categories/Categories')
    },
    {
      path: '/companies/:companyId/locations/:locationId/receipts',
      name: 'pointOfSale.receipts',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.receipts',
        noAddButton: true,
        requiresAuth: true,
        removeSearch: true,
        removeGrid: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/receipts/Receipts')
    },
    {
      path: '/companies/:companyId/locations/:locationId/associates',
      name: 'pointOfSale.associates',
      meta: {
        title: '$vuetify.pointsOfSaleTabs.associates',
        addItemText: '$vuetify.pointOfSale.addAssociateBtn',
        addItemAction: 'addAssociate',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/associates/Associates')
    },
    {
      path: '/companies/:companyId/locations/:locationId/users',
      name: 'pointOfSale.users',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/users/Users'),
      meta: {
        title: '$vuetify.users.titleText',
        addItemText: '$vuetify.users.addUser',
        addItemAction: 'addUser',
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/reports',
      name: 'pointOfSale.reports',
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/reports/reportPosd',
      name: 'pointOfSale.reports.reportPosd',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/reports/ReportPosd'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/reports/reportKpr',
      name: 'pointOfSale.reports.reportKpr',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/reports/ReportKpr'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/instructions',
      name: 'pointOfSale.instructions',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/instructions/Instructions'),
      meta: {
        title: '$vuetify.instructions.titleText',
        filterSearch: false,
        requiresAuth: true,
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/warehouseItems',
      name: 'pointOfSale.warehouseModule.items',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseItems'),
      meta: {
        noAddButton: true,
        title: '$vuetify.warehouse.titleText',
        addItemText: '$vuetify.warehouse.addItemsText',
        addItemAction: 'openAddWarehouseModuleItemForm',
        filterSearch: true,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/warehouseDocuments',
      name: 'pointOfSale.warehouseModule.documents',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocuments'),
      meta: {
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        title: '$vuetify.warehouse.titleText',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/items/norm',
      name: 'pointOfSale.items.norm',
      meta: {
        title: '$vuetify.companyTabs.norm',
        noAddButton: true,
        removeSearch: true,
        // addItemText: '$vuetify.company.addNormBtn',
        // addItemAction: 'openaddItemForm',
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/norm/Norm')
    },
    {
      path: 'companies/:companyId/locations/:locationId/userdata/bill',
      name: 'pointOfSale.userData.bill',
      meta: {
        title: '$vuetify.userDataTabs.bill',
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        requiresAuth: true,
        filterSearch: false,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/userdata/Bill')
    },
    {
      path: 'companies/:companyId/locations/:locationId/close-balance',
      name: 'pointOfSale.closeBalance.displayCloseBalance',
      meta: {
        title: '$vuetify.userDataTabs.bill',
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        requiresAuth: true,
        filterSearch: false,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/point-of-sale/components/close-balance/DisplayCloseBalanceList')
    },
    {
      path: '/companies/:companyId/locations/:locationId/groupageColection',
      name: 'pointOfSale.reports.groupageColection',
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/reports/GroupageCollection')
    },
    {
      path: '/companies/:companyId/locations/:locationId/restauraunt/menuItems',
      name: 'pointOfSale.restaurant.menuItems',
      component: () => import(/* webpackChunkName: "Restaraunt" */ '@/modules/point-of-sale/components/menu-item/MenuItems'),
      meta: {
        title: '$vuetify.restaurant.menu',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddMenuItem',
        addItemText: 'Dodaj meni',
        removeSearch: true,
        removeGrid: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/orders/createOrder',
      name: 'pointOfSale.orders.createOrder',
      component: () => import(/* webpackChunkName: "Orders" */ '@/modules/point-of-sale/components/orders/CreateOrder'),
      meta: {
        removeGrid: true,
        addItemText: '$vuetify.orders.createOrder',
        addItemAction: 'openCreateOrder',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/orders/showOrders',
      name: 'pointOfSale.orders.showOrders',
      component: () => import(/* webpackChunkName: "Orders" */ '@/modules/point-of-sale/components/orders/ShowOrders'),
      meta: {
        removeGrid: true,
        addItemText: '$vuetify.orders.showOrders',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/printerConfiguration',
      name: 'pointOfSale.printerConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/printer-configuration/PrinterConfiguration'),
      meta: {
        title: '$vuetify.printerConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddPrinter',
        addItemText: 'Dodaj printer',
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/organizationalUnitConfiguration',
      name: 'pointOfSale.organizationalUnitConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/organizational-unit-configuration/OrganizationalUnitConfiguration'),
      meta: {
        title: '$vuetify.orgUnitConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddOrgUnit',
        addItemText: 'Dodaj ustrojstvenu jedinicu',
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/suppliers',
      name: 'pointOfSale.suppliers',
      meta: {
        title: '$vuetify.companyTabs.suppliers',
        addItemText: '$vuetify.company.addSupplier',
        addItemAction: 'addSupplier',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/suppliers/Suppliers')
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/reportMenu',
      name: 'pointOfSale.all-reports.reportMenu',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/ReportMenu'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/recapitulation',
      name: 'pointOfSale.all-reports.recapitulation',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationTrade'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/recapitulationBuyers',
      name: 'pointOfSale.all-reports.recapitulationBuyers',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationBuyers'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/taxReport',
      name: 'pointOfSale.all-reports.taxReport',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/TaxReport'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/pnpReport',
      name: 'pointOfSale.all-reports.pnpReport',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/PnpReport'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/itemReport',
      name: 'pointOfSale.all-reports.itemReport',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/ItemReport'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/itemPricelistReport',
      name: 'pointOfSale.all-reports.itemPricelistReport',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/ItemPricelistReport'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/recapitulationTips',
      name: 'pointOfSale.all-reports.recapitulationTips',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationTips'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/recapitulationRmk',
      name: 'pointOfSale.all-reports.recapitulationRmk',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationRmk'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/inventories',
      name: 'pointOfSale.all-reports.inventories',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/Inventories'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/all-reports/warehouseCard',
      name: 'pointOfSale.all-reports.warehouseCard',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/WarehouseCard'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/warehouseDocumentsOverview',
      name: 'pointOfSale.warehouseModule.warehouseDocumentsOverview',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsOverview'),
      meta: {
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/suppliers/supplierItems',
      name: 'pointOfSale.suppliers.supplierItems',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/suppliers/SupplierItems'),
      meta: {
        title: '$vuetify.companyTabs.items',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/suppliers/supplierItemsUpload',
      name: 'pointOfSale.suppliers.supplierItemsUpload',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/suppliers/SupplierItemsUpload'),
      meta: {
        title: '$vuetify.companyTabs.items',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/orders/receiveOrder',
      name: 'pointOfSale.orders.receiveOrder',
      component: () => import(/* webpackChunkName: "Orders" */ '@/modules/point-of-sale/components/orders/other/receive-order/ReceiveOrderOther'),
      meta: {
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/suppliers/supplierIntegrations',
      name: 'pointOfSale.suppliers.supplierIntegrations',
      component: () => import('@/modules/company/components/suppliers/integration/SupplierIntegrations'),
      meta: {
        filterSearch: false,
        requiresAuth: true,
        addItemText: '$vuetify.company.addIntegration',
        addItemAction: 'addIntegration',
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/suppliers/supplierItemsPriceUpload',
      name: 'pointOfSale.suppliers.supplierItemsPriceUpload',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/suppliers/SupplierItemsPriceUpload'),
      meta: {
        title: '$vuetify.companyTabs.items',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/posConfiguration',
      name: 'pointOfSale.posConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/pos-configuration/PosConfiguration'),
      meta: {
        title: '$vuetify.posConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddPos',
        addItemText: 'Dodaj pos',
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/cardConfiguration',
      name: 'pointOfSale.cardConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/card-configuration/CardConfiguration'),
      meta: {
        title: '$vuetify.cardConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddCard',
        addItemText: 'Dodaj karticu',
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/activeInventory',
      name: 'pointOfSale.warehouseModule.activeInventory',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/Inventura/ActiveInventory'),
      meta: {
        title: '$vuetify.inventura.active',
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/inventories',
      name: 'pointOfSale.warehouseModule.inventories',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/Inventura/Inventories'),
      meta: {
        title: '$vuetify.inventura.title',
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/communicationConfiguration',
      name: 'pointOfSale.communicationConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/communication-configuration/CommunicationConfiguration'),
      meta: {
        title: '$vuetify.communicationConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddCommunication',
        addItemText: '$vuetify.add',
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/notifications',
      name: 'pointOfSale.notifications',
      component: () => import(/* webpackChunkName: "" */ '@/modules/company/components/notifications/Notifications'),
      meta: {
        title: '$vuetify.notifications.title',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/admin/companySettings',
      name: 'pointOfSale.admin.companySettings',
      component: () => import(/* webpackChunkName: "" */ '@/modules/company/components/admin/CompanySettings'),
      meta: {
        title: '$vuetify.companySettings.title',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/staff',
      name: 'pointOfSale.workOrder.staff',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/workorder/WorkOrderStaff'),
      meta: {
        title: '$vuetify.workorder.staff',
        addItemAction: 'openAddStaff',
        addItemText: '$vuetify.add',
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/admin/companyAdd',
      name: 'pointOfSale.admin.companyAdd',
      component: () => import(/* webpackChunkName: "" */ '@/modules/company/components/admin/CompanyAdd'),
      meta: {
        title: '$vuetify.companyAdd.title',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/fields',
      name: 'pointOfSale.workOrder.fields',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/workorder/WorkOrderFields'),
      meta: {
        title: '$vuetify.workorder.fields',
        addItemAction: 'openAddField',
        addItemText: '$vuetify.add',
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/warehouseDocumentsv2',
      name: 'pointOfSale.warehouseModule.documentsv2',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsv2'),
      meta: {
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        title: '$vuetify.warehouse.titleText',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    },
    {
      path: '/companies/:companyId/locations/:locationId/workorders',
      name: 'pointOfSale.workOrders',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/workorder/WorkOrders'),
      meta: {
        title: '$vuetify.workorders',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'PointOfSale',
        topBar: 'PointOfSaleTopBar'
      }
    }
  ]
}
