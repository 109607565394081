<template>
  <div>
    <v-container style="width: 100%">
      <div>
        <v-row>
          <v-col cols="10">
            <!-- <div style="padding-left: 5%; font-weight: bold">Radni nalozi</div> -->
            <WorkOrderList
              ref="generalReceipts"
              style="padding-top: 0px"
            ></WorkOrderList>
          </v-col>
          <v-col cols="2" style="text-align: center">
            <table>
              <tbody>
                <tr>
                  <td>
                    <v-btn
                      id="success"
                      style="width: 100%"
                      class="btn successButton"
                      @click="openWorkOrderAdd"
                    >
                      Novi radni nalog
                    </v-btn>
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </div>
      <work-order-add ref="workOrderAdd"></work-order-add>
      <v-dialog
        v-model="workorderlistModal"
        style="
          background-color: white;
          width: 70% !important;
          border-radius: 25px;
        "
        name="workorderlist"
      >
        <WorkOrderList
          style="background-color: white"
          ref="dialogWorkOrderList"
        ></WorkOrderList>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>

import WorkOrderAdd from '@/modules/point-of-sale/components/workorder/WorkOrderAdd'
import WorkOrderList from '@/modules/point-of-sale/components/workorder/WorkOrderList'
import EventBus from '@/plugins/event-bus'
import state from '@/state'

export default {
  components: {
    WorkOrderList,
    WorkOrderAdd
  },
  inject: [

  ],
  data: function (vm) {
    return {
      loadingDialog: false,
      top: true,
      right: true,
      bottom: false,
      left: false,
      transition: 'slide-x-reverse-transition',
      direction: 'bottom',
      fab: true,
      bla: true,
      fling: false,
      hover: false,
      tabs: null,
      buttons: [
        { name: 'Novi radni nalog', action: vm.openWorkOrderAdd },
        { name: '', action: vm.openWorkOrderAdd },
        { name: '', action: vm.openWorkOrderAdd },
        { name: 'Zaključak blagajne', action: vm.openWorkOrderAdd },
        { name: 'Primka', action: vm.openWorkOrderAdd }
      ],
      workorderlistModal: false,
      vm: vm,
      receiptFilterDialog: {}
    }
  },
  computed: {

    lastBalanceTimestamp () {
      var balanceTimestamp = state.getCashRegister().last_balance_timestamp
      if (balanceTimestamp) {
        return this.$options.filters.local(balanceTimestamp)
      } else {
        return ''
      }
    }

  },

  mounted () {
    EventBus.$off('stopButtonLoader')
    EventBus.$on('stopButtonLoader', (data) => {
      this.loadingDialog = false
    })

    EventBus.$off('refreshCashRegister')
    EventBus.$on('refreshCashRegister', () => {
      // if (this.$refs.generalReceipts) {
      //   this.$refs.generalReceipts.changeBalanceTimestampFilter(state.getCashRegister().last_balance_timestamp)
      // }
    })
  },
  methods: {
    close () {
      this.workorderlistModal = false
    },
    closeBalance () {
      if (this.$refs.closeBalance) this.$refs.closeBalance.open()
    },
    openCloseBalance () {
      if (this.$refs.closeBalance) this.$refs.closeBalance.open()
    },
    openWorkOrderAdd () {
      this.loadingDialog = true

      var register = state.getCashRegister()
      this.$refs.workOrderAdd.open(this.loadingDialog, register)
    },
    openWorkOrders () {
      this.workorderlistModal = true
    }

  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
td {
  background-color: white;
  padding: 2px;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.new-receipt-fab {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.categories {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 64px;
  z-index: 3;
  width: 100%;
}

.categories >>> .v-tabs-bar__content {
  justify-content: center;
  margin-top: 40px;
}

.myTest >>> .vm--modal {
  bottom: 100px !important;
  z-index: 200;
  border-radius: 25px !important;
}

.container {
  background-color: white;
}
</style>
