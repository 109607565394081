<template>
  <div :class="modal ? 'okvir-modal' : 'okvir'">
    <v-overlay v-if="loading"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      style="z-index: 100">
      <div v-if="loading"
        class="text-center"
        style="
          position: absolute;
          z-index: 101;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        ">
        <v-progress-circular indeterminate
          size="86"
          color="primary">
        </v-progress-circular>
      </div>
    </v-overlay>
    <div class="resizable"
      :style="modal
        ? 'text-align: center; border-radius: 25px !important;'
        : `text-align: center;margin-left: ${margin}`
        ">
      <v-card tile
        color="white"
        class="pb-0 mb-0"
        :style="modal ? 'border-radius: 25px !important' : ''">
        <v-card-title v-if="modal"
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">Unos otpisa</span>
        </v-card-title>
        <v-form ref="primkaForm"
          @submit.prevent="submit">
          <div :style="modal
            ? 'display: flex; margin-top: 15px; margin-left: 5px; margin-right: 5px;'
            : 'display: flex; margin-top: 5px;'
            ">
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              label="Prodajno mjesto"
              v-model="selectedLocation"
              :items="locations"
              item-text="name"
              item-value="value"
              auto
              return-object
              v-if="locations.length > 1"
              @change="getWarehouses()"
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              label="Skladište"
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              v-if="warehouses.length > 1"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <!-- <label class="datepickerLabel">Datum</label> -->
            <date-picker ref="datepicker"
              :clearable="false"
              prefix-class="xmx"
              :append-to-body="false"
              class="datetimepicker"
              v-model="invoiceDate"
              placeholder="Datum"
              type="date"
              valueType="timestamp"
              format="DD.MM.YYYY"
              :default-value="new Date()"
              :popup-style="{}"
              :rules="[rules.req]">
            </date-picker>
            <v-text-field class="pl-2"
              hide-details
              outlined
              label="Broj otpisa"
              dense
              v-model="brojPrimke"
              :rules="[rules.req]"></v-text-field>
          </div>
        </v-form>
        <!-- <v-row>
        <v-col style="align-items: left; text-align: left">
          <v-btn id="success" class="btn infoButton mt-2 mb-2" @click="addRow"
            >Dodaj artikl</v-btn
          >
        </v-col>
      </v-row> -->
        <div class="table-sm"
          ref="table"></div>
        <v-card-actions class="pa-0 ma-0 mr-5">
          <v-row>
            <v-col style="align-items: right; text-align: right">
              <v-menu top
                origin="center center"
                offset-y
                style="background-color: red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn id="success"
                    class="btn warningButton mt-2 mb-2 mr-2"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="custom-list">
                  <v-list-item @click="clearData"
                    class="btn dangerButton custom-list-item">
                    <v-list-item-title>
                      <span>Obriši sve</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn id="success"
                v-if="modal"
                class="btn dangerButton mt-2 mb-2 mr-2"
                @click="closeModal()">Odustani</v-btn>
              <v-btn id="success"
                class="btn successButton mt-2 mb-2"
                @click="presubmit">Spremi</v-btn>
            </v-col></v-row>
        </v-card-actions>
      </v-card>
    </div>
    <fab-menu v-if="!$vuetify.breakpoint.smAndDown"></fab-menu>
  </div>
</template>
<script>
import { TabulatorFull as Tabulator, CalcComponent } from 'tabulator-tables'
import { auth, df, functions } from '@/plugins/firebase'
import state from '@/state'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'
import FabMenu from '@/modules/company/components/FabMenu'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    DatePicker,
    FabMenu
  },
  modules: {},
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  props: {
    modal: Boolean
  },
  data: function () {
    return {
      checkboxes: {},
      absolute: true,
      opacity: 0.46,
      overlay: true,
      loading: false,
      tableHeight: window.innerHeight - 185 + 'px',
      tableWidth: window.innerWidth - 200 + 'px',
      margin: '56px',
      rules: {
        req: rules.req()
      },
      tabulator: null, // variable to hold your table
      locations: [],
      selectedLocation: undefined,
      warehouses: [],
      selectedWarehouse: undefined,
      invoiceDate: Date.now(),
      brojPrimke: undefined,
      tableData: [],
      artikli: []
    }
  },
  methods: {
    recalculate () {
      if (!this.tabulator) {
        return
      }
      this.tabulator.redraw(true)
    },

    closeModal () {
      this.$emit('closeModal')
    },
    calculateHeights () {
      this.tabulator.setHeight(window.innerHeight - 185)
    },
    presubmit () {
      if (!this.$refs.primkaForm.validate()) return
      if (this.tabulator.validate() !== true) return

      this.confirm({
        title: 'Otpis',
        message: 'Jeste li sigurni da želite spremiti trenutne podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Spremi',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.submit()
        }
      })
    },
    submit () {
      this.loading = true
      var writeOffItems = []

      this.tabulator.getData().forEach((element) => {
        writeOffItems.push({
          source_item_id: element.id,
          source_item_name: element.artikl,
          quantity: Math.round(Number(element.kolicina_otpis)) * 1000
        })
      })

      var writeoffApi = functions.httpsCallable('writeoffapi')

      writeoffApi({
        action: {
          operation: 'set',
          entity: 'write_off',
          resource_id: '',
          params: {
            warehouse_id: this.selectedWarehouse.id,
            selling_warehouse: this.selectedWarehouse.selling_warehouse,
            location_id: this.selectedWarehouse.location_id,
            write_off_items: writeOffItems,
            document_type: 'otpis',
            user_id: auth.currentUser.uid,
            user_name: state.getUser().name + ' ' + state.getUser().surname,
            document_number: this.brojPrimke
          }
        }
      }).then((result) => {
        if (result.data.code === 200) {
          this.loading = false
          this.showMsgBox({
            text: 'Uspješno spremljen otpis.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.createTable()
          if (this.modal) {
            this.closeModal()
          }
        } else {
          this.loading = false
          this.showMsgBox({
            text: 'Nije uspjelo spremanje otpisa.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        }
      })
    },
    clearData () {
      this.confirm({
        title: 'Brisanje podataka',
        message: 'Jeste li sigurni da želite obrisati upisane podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Obriši',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.tabulator.setData([])
        } else {
        }
      })
    },
    addRow () {
      this.$refs.primkaForm.validate()
      if (!this.selectedLocation) return
      if (this.tabulator.validate() !== true) return
      this.tabulator.addRow({}).then(function (row) {
        // Dobijte ćeliju prve kolone novog retka
        var cell = row.getCell('artikl')
        if (cell) {
          // Start editing the cell
          cell.edit()
        }
      })
    },

    numberValidation (cell, num, parameters) {
      let allowZero = false
      if (parameters?.allowZero ?? false) {
        allowZero = true
      }
      if (!num || num === '' || (num === 0 && !allowZero)) return false
      var bla = num.toString().trim()
      if (bla === '') return false
      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      if (allowZero && parseInt(Math.round(number * 100)) >= 0) return true
      if (parseInt(Math.round(number * 100)) > 0) return true
      return false
    },

    // numberValidation (cell, num, parameters) {
    //   let allowZero = false
    //   if (parameters?.allowZero ?? false) {
    //     allowZero = true
    //   }
    //   if (!num || num === '' || (num === 0 && !allowZero)) return false
    //   var bla = num.toString().trim()
    //   if (bla === '') return false
    //   var number = bla.replace(/,/g, '.')
    //   number = parseFloat(number)
    //   if (allowZero && parseInt(Math.round(number * 100)) >= 0) return true
    //   if (parseInt(Math.round(number * 100)) > 0) return true
    //   return false
    // },
    async getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
            })
            if (this.locations.length === 1) {
              this.selectedLocation = this.locations[0]
              this.getWarehouses()
            }
            this.locations = this.locations.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
            this.$forceUpdate()
          }
        })
    },

    async getWarehouses () {
      this.warehouses = []
      df.collection('warehouses')
        .where('location_id', '==', this.selectedLocation.id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((warehouse) => {
              const loc = warehouse.data()
              this.warehouses.push(loc)
            })
            if (this.warehouses.length === 1) {
              this.selectedWarehouse = this.warehouses[0]
            }
            this.warehouses = this.warehouses.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
            this.$forceUpdate()
          }
        })
    },
    async getItems () {
      const query = df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')
        .where('type', '==', 'GOODS')

      const documentSnapshots = await query.get()
      if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
        documentSnapshots.docs.forEach((it) => {
          const item = it.data()

          if (!item.has_norm) {
            if (item.warehouse_units?.units) {
              const map = item.warehouse_units.units
              const entries = Object.entries(map)
              entries.sort(([keyA, valueA], [keyB, valueB]) => {
                return (
                  (valueB.default_store === true) -
                  (valueA.default_store === true)
                )
              })
              const sortedMap = Object.fromEntries(entries)
              var unit = []
              for (const key in sortedMap) {
                unit.push({ label: map[key].storage_unit, value: map[key].id })
              }
              this.artikli.push({
                id: item.id,
                label: item.name,
                value: item.name,
                mjerneJedinice: unit,
                stopaPdv: item.tax_group_object?.pdv_rate ?? '',
                item: item
              })
            }
          }
        })

        var povratnaUnitId = uuidv4()

        this.artikli.push({
          id: '-1',
          label: 'Povratna naknada',
          value: 'Povratna naknada',
          mjerneJedinice: [{ label: 'kom', value: povratnaUnitId }],
          stopaPdv: '0',
          // cijena: '0.07',
          item: {
            id: '-1',
            name: 'Povratna naknada',
            warehouse_units: {
              base_unit: povratnaUnitId,
              default_sale: povratnaUnitId,
              default_store: povratnaUnitId,
              units: {
                [povratnaUnitId]: {
                  base_unit: true,
                  default_sale: true,
                  default_store: true,
                  id: povratnaUnitId,
                  is_selling_item: true,
                  storage_amount: 1000,
                  storage_unit: 'kom'
                }
              }
            }
          }
        })
      }
      this.artikli = this.artikli.sort((a, b) =>
        a.label.localeCompare(b.label)
      )
    },
    createTable () {
      var that = this

      var rowContextMenu = [
        {
          label:
            "<span class='mdi mdi-content-copy iclass btn infoButton'> Kopiraj artikl</span>",
          action: function (e, row) {
            var rowData = row.getData()
            const copiedData = JSON.parse(JSON.stringify(rowData))
            that.tabulator.addRow(copiedData)
          }
        },
        {
          label:
            "<span class='mdi mdi-delete-forever iclass btn dangerButton'> Obriši artikl</span>",
          action: function (e, row) {
            row.delete()
          }
        },
        {
          separator: true
        }
        // {
        //   label: 'Admin',
        //   menu: [
        //     {
        //       label: '<i ></i> Obriši redak',
        //       action: function (e, row) {
        //         row.delete()
        //       }
        //     },
        //     {
        //       label: '<i ></i> Disabled Option',
        //       disabled: true
        //     }
        //   ]
        // }
      ]
      this.tabulator = new Tabulator(this.$refs.table, {
        persistence: false,
        layout: 'fitColumns',
        height: this.tableHeight,
        rowContextMenu: rowContextMenu,
        rowheader: true,
        keybindings: {
          addNewRow: 'ctrl + n'
        },
        data: this.tableData, // link data to table
        reactiveData: true, // enable data reactivity
        columns: [
          { visible: false, field: 'id' },
          {
            formatter: 'rownum',
            hozAlign: 'center',
            width: 40,
            headerSort: false,
            titleFormatter: function (cell, formatterParams, onRendered) {
              const el = document.createElement('button')
              el.innerHTML = '+'
              el.classList.add('butt')
              el.classList.add('infoButton')
              el.onclick = function () {
                that.addRow()
              }

              return el
            }
          },
          {
            title: 'Artikl',
            field: 'artikl',
            width: 190,
            validator: 'required',
            editor: 'list',
            editorParams: {
              values: this.artikli,
              autocomplete: 'true',
              allowEmpty: false,
              listOnEmpty: true
            },
            headerSort: false,
            cellEdited: async function (cell) {
              var row = cell.getRow()
              var artiklValue = cell.getValue()
              var selectedArtikl = this.artikli.find(
                (item) => item.value === artiklValue && !item.id.includes('*')
              )

              const whItem = await df
                .doc(`warehouses/${this.selectedWarehouse.id}/extended_items/${selectedArtikl.id}`)
                .get()

              var itemData = whItem.data()

              if (selectedArtikl && itemData) {
                row.update({ mjernaJedinica: '' }) // Resetiraj polje mjernaJedinica
                row.update({
                  mjernaJedinica: selectedArtikl.mjerneJedinice[0].value
                })
                row.update({ mjernaJedinicaId: '' }) // Resetiraj polje mjernaJedinica
                row.update({
                  mjernaJedinicaId: selectedArtikl.mjerneJedinice[0].value
                })

                row.update({ kolicina: itemData.warehouse_data.quantity / 1000 })
                row.update({ stopaPdv: '' })
                row.update({ stopaPdv: selectedArtikl.stopaPdv + '' })
                row.update({ id: selectedArtikl.id })
                row.update({ jedinicnaCijenaSPdv: selectedArtikl.cijena })
                row.update({ item: selectedArtikl.item })
                this.tabulator.redraw()
                this.tabulator.navigateRight()
              }
            }.bind(this)
          },
          {
            title: 'Trenutna količina',
            field: 'kolicina',
            hozAlign: 'right',
            // mutator: function (value, data) {
            //   if (value) {
            //     return value.replace(/,/g, '.')
            //   } else return 0
            // },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Količina: ' + cell.getValue()
              }
            },
            formatter: 'numberFormat',
            bottomCalcFormatter: 'numberFormat',
            bottomCalc: 'sum',
            bottomCalcParams: { precision: 2 },
            headerSort: false

          },
          {
            title: 'Količina za otpis',
            field: 'kolicina_otpis',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Količina za otpis: ' + cell.getValue()
              }
            },
            editor: 'input',
            editorParams: { selectContents: true },
            validator: [
              {
                type: (cell, value, parameters) => {
                  const rowData = cell.getRow().getData()
                  const kolicina = parseFloat(rowData.kolicina || 0)
                  const kolicinaOtpis = parseFloat(value || 0)
                  if (kolicinaOtpis > kolicina) {
                    return false // Neispravno ako je veće
                  }
                  return true // Ispravno
                },
                parameters: {},
                message: 'Količina za otpis ne može biti veća od količine.'
              },
              'required'
            ],
            formatter: 'numberFormat',
            bottomCalcFormatter: 'numberFormat',
            bottomCalc: 'sum',
            bottomCalcParams: { precision: 2 },
            headerSort: false,
            cellEdited: this.kalkulacijaCijene
          }
          // {
          //   title: 'Mj. jedinica',
          //   field: 'mjernaJedinica',
          //   validator: 'required',
          //   editor: 'list',
          //   editorParams: function (cell) {
          //     var row = cell.getRow()
          //     var artiklNaziv = row.getData().artikl
          //     var selectedArtikl = this.artikli.find(
          //       (artikl) => artikl.value === artiklNaziv
          //     )

          //     if (selectedArtikl) {
          //       return { values: selectedArtikl.mjerneJedinice }
          //     } else {
          //       return { values: [] }
          //     }
          //   }.bind(this),
          //   formatter: function (cell, formatterParams, onRendered) {
          //     var value = cell.getValue()
          //     if (!value) {
          //       return
          //     }
          //     var row = cell.getRow()
          //     var artiklNaziv = row.getData().artikl.trim()
          //     var selectedArtikl = that.artikli.find(
          //       (artikl) => artikl.value.trim() === artiklNaziv
          //     )

          //     var option = selectedArtikl.mjerneJedinice.find(
          //       (opt) => opt.value === value
          //     )
          //     return option ? option.label : value
          //   },
          //   headerSort: false
          // },
          // ,
          // {
          //   formatter: 'buttonCross',
          //   hozAlign: 'center',
          //   cellClick: function (e, cell) {
          //     cell.getRow().delete()
          //   // TRIGGER POST REQUEST
          //   },
          //   headerSort: false
          // }
        ]
      })

      Tabulator.extendModule('keybindings', 'actions', {
        addNewRow: function () {
          that.tabulator.addRow({}).then(function (row) {
            var cell = row.getCell('artikl')
            if (cell) {
              cell.edit()
            }
          })
        }
      })

      Tabulator.extendModule('format', 'formatters', {
        numberFormat: function (cell, formatterParams) {
          const num = cell.getValue()
          if (num) {
            const input = cell.getValue().toString().replace(',', '.')

            // Parse the input as a floating point number
            const num = parseFloat(input)

            // Check for invalid input
            if (isNaN(num)) {
              return
              // throw new Error('Invalid input')
            }

            // Handle cases where there are up to 3 decimal places
            if (input.includes('.')) {
              const decimalPart = input.split('.')[1]
              if (decimalPart.length <= 3) {
                return num.toFixed(2).replace('.', ',')
              } else {
                return num.toFixed(3).replace('.', ',')
              }
            } else {
              // No decimal point, so just add ",00"
              if (formatterParams.percentage) {
                return num.toFixed(2).replace('.', ',') + '%'
              }
              return num.toFixed(2).replace('.', ',')
            }
          }
        }
      })
    }

  },
  created () {
    window.addEventListener('resize', this.calculateHeights)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
    window.removeEventListener('resize', this.calculateHeights)
  },
  mounted () {
    this.margin = state.getMargin()
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    if (this.modal) {
      this.margin = '0px'
    }
    this.getLocations()
    this.getItems()

    this.createTable()
  }
}
</script>
<style>
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal;
  text-overflow: clip;
  text-align: center;
  font-size: small;
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-col {
  background-color: #f2f2f2 !important;
}

.tabulator .tabulator-row .tabulator-cell {
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-edit-select option {
  height: 30px;
  /* Postavite željenu visinu stavki u dropdownu */
}

/* CSS medijski upiti */
.resizable {
  width: 100%;
}

/* @media (min-width: 1200px) {
  .resizable {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .resizable {
    width: 95%;
  }
}

@media (max-width: 767px) {
  .resizable {
    width: 95%;
  }
} */

.tabulator-edit-list .tabulator-edit-list-item {
  padding: 4px;
  height: 50px;
  color: #333;
  outline: none;
  vertical-align: middle;
  font-size: 18px;
  padding: 16px 0 0 10px;
}

.butt {
  border-radius: 5px;
  border: 2px solid black;
  background-color: white !important;
  color: black;
  padding: 0px 5px 0px 5px;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
}

.iclass {
  font-size: 20px;
  /* Veličina fonta */
  height: 30px;
  /* Visina ikone */
  line-height: 30px;
  /* Postavljanje vertikalnog centriranja */
  display: inline-flex;
  /* Omogućava postavljanje dimenzija i centriranje */
  align-items: center;
  /* Vertikalno centriranje */
  justify-content: center;
  /* Horizontalno centriranje */
  transition: color 0.3s, background-color 0.3s;
  /* Glatka tranzicija za promenu boje */
  color: black;
  /* Inicijalna boja teksta */
  background-color: white;
  /* Inicijalna pozadinska boja */
  padding: 5px;
  /* Unutrašnja margina za bolji izgled */
  border-radius: 5px;
  /* Zaobljeni uglovi */
}

/* Promena boje teksta i pozadinske boje na hover */
.iclass:hover {
  color: white;
  /* Promena boje teksta na hover */
  background-color: red;
  /* Promena pozadinske boje na hover */
}
</style>

<style scoped>
.table-sm {
  margin-top: 10px;
}

.custom-list {
  padding: 0px !important;
}

.custom-list-item {
  padding-bottom: 2px !important;
  background-color: transparent;
}

.okvir-modal {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
  border-radius: 25px !important;
}

.okvir {
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
}
</style>
