<template>
  <div style="border-radius: 25px !important">
    <v-dialog v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      persistent
      max-width="450"
      style="height: 400px !important">
      <v-overlay v-if="loading"
        z-index="1000"
        style="height: 100%; width: 100%; opacity: 1 !important">
        <v-progress-circular :size="100"
          :width="10"
          style="color: #04aa6d; z-index: 1000"
          indeterminate>Pričekajte</v-progress-circular>
      </v-overlay>
      <v-form>
        <v-card tile
          color="white"
          class="pb-3"
          style="border-radius: 25px !important; height: 500px !important">
          <v-card-title class="d-flex"
            style="
              vertical-align: middle;
              background-color: #1577da;

              height: 58px;
              color: white;
            ">
            <span class="text-h5"
              style="font-weight: bold">Dodaj dodatno polje</span>
          </v-card-title>
          <v-card-text style="padding: 0; margin: 0; overflow-x: hidden">
            <v-row class="flex-wrap justify-center pr-4 pl-4">
              <v-col>
                <v-row class="pt-0 mt-2 pb-0 mb-0">
                  <v-col cols="12"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field type="number"
                      dense
                      label="Redni broj"
                      outlined
                      hide-details
                      v-model="orderNumber"
                      :rules="[rules.req]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0 mt-2 pb-0 mb-0">
                  <v-col cols="12"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-select dense
                      label="Tip polja"
                      outlined
                      hide-details
                      v-model="selectedType"
                      :items="availableTypes"
                      item-text="name"
                      item-value="id"
                      :rules="[rules.req]"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      hide-details
                      outlined
                      label="Labela"
                      v-model="label"
                      :rules="[rules.req]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field v-if="selectedType !== 'checkbox'"
                      class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      hide-details
                      outlined
                      label="Zadana vrijednost"
                      v-model="defaultValue">
                    </v-text-field>

                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6"
                    class="pl-2 pb-1">
                    <v-checkbox class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      outlined
                      hide-details
                      label="Prikaži na ispisu"
                      v-model="printable">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6"
                    class="pl-2 pr-0 pb-1">
                    <v-checkbox class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      outlined
                      hide-details
                      label="Obavezan parametar"
                      v-model="mandatory">
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="selectedType === 'dropdown'"
                  class="pt-0 mt-0 pb-0 mb-0">
                  <div style="width: 47.5%"
                    class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-combobox clearable
                      :items="availableDropdownValues"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Predefinirane vrijednosti"
                      v-model="dropdownValue"
                      :search-input.sync="dropdownValue">
                    </v-combobox>
                  </div>
                  <div style="width: 15%"
                    class="pl-6 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton"
                      @click="addDropdownValue()"><v-icon>mdi-plus-outline</v-icon></v-btn>
                  </div>
                  <!-- <div style="width: 15%" class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton" @click="saveDropdownValue()"
                      ><v-icon>mdi-content-save-check-outline</v-icon></v-btn
                    >
                  </div> -->
                </v-row>
                <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12"
                    v-if="
                      selectedType === 'dropdown' &&
                      addedDropdownValues &&
                      addedDropdownValues.length > 0
                    ">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%"
                            colspan="1">Br.</th>
                          <th width="80%"
                            colspan="1">Predefinirani odabir</th>
                          <th width="10%"
                            colspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in addedDropdownValues"
                          :key="ct">
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            {{ key + 1 }}
                          </td>
                          <td style="text-align: center"
                            width="80%"
                            colspan="1">
                            {{ ct }}
                          </td>
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            <v-icon @click="removeaddedDropdownValues(key)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>

                <v-row v-if="selectedType === 'radio'"
                  class="pt-0 mt-0 pb-0 mb-0">
                  <div style="width: 47.5%"
                    class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-combobox clearable
                      :items="availableRadioValues"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Predefinirane vrijednosti"
                      v-model="radioValue"
                      :search-input.sync="radioValue">
                    </v-combobox>
                  </div>
                  <div style="width: 15%"
                    class="pl-6 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton"
                      @click="addRadioValue()"><v-icon>mdi-plus-outline</v-icon></v-btn>
                  </div>
                </v-row>
                <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12"
                    v-if="
                      selectedType === 'radio' &&
                      addedRadioValues &&
                      addedRadioValues.length > 0
                    ">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%"
                            colspan="1">Br.</th>
                          <th width="80%"
                            colspan="1">Predefinirani odabir</th>
                          <th width="10%"
                            colspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in addedRadioValues"
                          :key="ct">
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            {{ key + 1 }}
                          </td>
                          <td style="text-align: center"
                            width="80%"
                            colspan="1">
                            {{ ct }}
                          </td>
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            <v-icon @click="removeaddedRadioValues(key)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>

                <v-row v-if="selectedType === 'checkbox'"
                  class="pt-0 mt-0 pb-0 mb-0">
                  <div style="width: 47.5%"
                    class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-combobox clearable
                      :items="availableCheckboxValues"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Predefinirane vrijednosti"
                      v-model="checkboxValue"
                      :search-input.sync="checkboxValue">
                    </v-combobox>
                  </div>
                  <div style="width: 15%"
                    class="pl-6 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton"
                      @click="addCheckboxValue()"><v-icon>mdi-plus-outline</v-icon></v-btn>
                  </div>
                  <div style="width: 15%"
                    class="pl-9 pt-0 mt-2 pb-0 mb-0">
                    <v-checkbox v-model="checkboxSelected"
                      label="Odabrano"></v-checkbox>
                  </div>

                </v-row>
                <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12"
                    v-if="
                      selectedType === 'checkbox' &&
                      addedCheckboxValues &&
                      addedCheckboxValues.length > 0
                    ">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%"
                            colspan="1">Br.</th>
                          <th width="50%"
                            colspan="1">Predefinirani odabir</th>
                          <th width="10%"
                            colspan="1">Odabrano</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in addedCheckboxValues"
                          :key="key">
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            {{ key + 1 }}
                          </td>
                          <td style="text-align: center"
                            width="50%"
                            colspan="1">
                            {{ ct.name }}
                          </td>
                          <td style="text-align: center"
                            width="30%"
                            colspan="1">
                            {{ ct.selected ? 'Da' : 'Ne' }}
                          </td>
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            <v-icon @click="removeaddedCheckboxValues(key)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex pt-3 mt-0 justify-end">
            <v-btn class="btn dangerButton"
              @click="close"> Odustani </v-btn>
            <v-btn :disabled="checkFinish()"
              id="success"
              class="btn successButton"
              @click="addField()">
              Završi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'

export default {
  components: {},
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    dialogOpen: false,
    location: undefined,
    locationId: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: [],
    loading: false,
    selectedType: undefined,
    availableTypes: [
      { id: 'textfield', name: 'Polje za unos teksta' },
      { id: 'textarea', name: 'Veliko polje za unos teksta' },
      { id: 'dropdown', name: 'Odabir predefiniranih vrijednosti' },
      { id: 'date', name: 'Datum' },
      { id: 'checkbox', name: 'Potvrdni okvir' },
      { id: 'radio', name: 'Radio gumb' }
    ],
    label: '',
    mandatory: false,
    printable: false,
    defaultValue: '',
    dropdownValue: '',
    availableDropdownValues: [],
    addedDropdownValues: [],
    radioValue: '',
    availableRadioValues: [],
    addedRadioValues: [],
    checkboxValue: '',
    availableCheckboxValues: [],
    addedCheckboxValues: [],
    orderNumber: undefined,
    checkboxSelected: undefined
  }),
  mounted () {
    this.getAvailableDropdownValues()
    this.getAvailableRadioValues()
    this.getAvailableCheckboxValues()

    this.orderNumber = this.nextFieldNumber
  },
  methods: {
    checkFinish () {
      if (!this.label || this.label === '') {
        return true
      }

      if (!this.orderNumber || this.orderNumber <= 0) {
        return true
      }

      if (!this.selectedType || this.selectedType === '') {
        return true
      }

      return false
    },
    removeaddedDropdownValues (index) {
      this.addedDropdownValues.splice(index, 1)
    },
    async getAvailableDropdownValues () {
      this.availableDropdownValues = []
      const snapshot = await df.doc(`workorder_dropdown_value/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.values && document.values.length > 0) {
          this.availableDropdownValues = [...document.values]
          this.availableDropdownValues.sort((a, b) => a.localeCompare(b))
        }
      }
    },
    addDropdownValue () {
      this.addedDropdownValues.push(this.dropdownValue)
    },
    async saveDropdownValue (added) {
      var newDropDownValues = [...this.availableDropdownValues]
      newDropDownValues.push(added)

      if (this.availableDropdownValues.length === 0) {
        const docRef = df.doc(`workorder_dropdown_value/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            values: newDropDownValues
          })
      } else {
        df.doc(`workorder_dropdown_value/${state.getCurrentCompany().id}`)
          .update({
            values: newDropDownValues
          })
      }

      this.getAvailableDropdownValues()
    },

    removeaddedRadioValues (index) {
      this.addedRadioValues.splice(index, 1)
    },
    async getAvailableRadioValues () {
      this.availableRadioValues = []
      const snapshot = await df.doc(`workorder_radio_value/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.values && document.values.length > 0) {
          this.availableRadioValues = [...document.values]
          this.availableRadioValues.sort((a, b) => a.localeCompare(b))
        }
      }
    },
    addRadioValue () {
      this.addedRadioValues.push(this.radioValue)
    },
    async saveRadioValue (added) {
      var newRadioValues = [...this.availableRadioValues]
      newRadioValues.push(added)

      if (this.availableRadioValues.length === 0) {
        const docRef = df.doc(`workorder_radio_value/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            values: newRadioValues
          })
      } else {
        df.doc(`workorder_radio_value/${state.getCurrentCompany().id}`)
          .update({
            values: newRadioValues
          })
      }

      this.getAvailableRadioValues()
    },

    removeaddedCheckboxValues (index) {
      this.addedCheckboxValues.splice(index, 1)
    },
    async getAvailableCheckboxValues () {
      this.availableCheckboxValues = []
      const snapshot = await df.doc(`workorder_checkbox_value/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.values && document.values.length > 0) {
          this.availableCheckboxValues = [...document.values]
          this.availableCheckboxValues.sort((a, b) => a.localeCompare(b))
        }
      }
    },
    addCheckboxValue () {
      this.addedCheckboxValues.push({ name: this.checkboxValue, selected: this.checkboxSelected })
      this.checkboxSelected = undefined
    },
    async saveCheckboxValue (added) {
      var newCheckboxValues = [...this.availableCheckboxValues]
      newCheckboxValues.push(added)

      if (this.availableCheckboxValues.length === 0) {
        const docRef = df.doc(`workorder_checkbox_value/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            values: newCheckboxValues
          })
      } else {
        df.doc(`workorder_checkbox_value/${state.getCurrentCompany().id}`)
          .update({
            values: newCheckboxValues
          })
      }

      this.getAvailableCheckboxValues()
    },

    open (nextFieldNumber) {
      this.getAvailableDropdownValues()
      this.getAvailableRadioValues()
      this.getAvailableCheckboxValues()

      this.orderNumber = nextFieldNumber

      this.dialogOpen = true
      this.submitting = false
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.selectedType = undefined
      this.label = ''
      this.mandatory = false
      this.printable = false
      this.defaultValue = ''
      this.dropdownValue = ''
      this.availableDropdownValues = []
      this.addedDropdownValues = []
      this.radioValue = ''
      this.availableRadioValues = []
      this.addedRadioValues = []
      this.checkboxValue = ''
      this.availableCheckboxValues = []
      this.addedCheckboxValues = []
      this.dialogOpen = false
      this.orderNumber = undefined
    },
    addField () {
      if (!this.label || this.label === '') {
        return
      }

      this.loading = true

      this.addedDropdownValues.forEach(added => {
        if (!this.availableDropdownValues.includes(added)) {
          this.saveDropdownValue(added)
        }
      })

      this.addedRadioValues.forEach(added => {
        if (!this.availableRadioValues.includes(added)) {
          this.saveRadioValue(added)
        }
      })

      this.addedCheckboxValues.forEach(added => {
        if (!this.addedCheckboxValues.includes(added)) {
          this.saveCheckboxValue(added)
        }
      })

      var workorderApi = functions.httpsCallable('workorderapi')

      var params = {
        action: {
          operation: 'set',
          entity: 'field',
          resource_id: '',
          params: {
            label: this.label,
            field_type: this.selectedType,
            mandatory: this.mandatory,
            default_value: this.defaultValue,
            printable: this.printable,
            company_id: state.getCurrentCompany().id,
            order_number: Number(this.orderNumber)
          }
        }
      }

      if (this.selectedType === 'dropdown') {
        params.action.params.dropdown_values = this.addedDropdownValues
      }

      if (this.selectedType === 'radio') {
        params.action.params.radio_values = this.addedRadioValues
      }

      if (this.selectedType === 'checkbox') {
        params.action.params.checkbox_values = this.addedCheckboxValues
      }

      workorderApi(
        params
      )
        .then((result) => {
          this.loading = false
          if (result.data.code === 200) {
            this.$emit('refreshFields')
            this.showMsgBox({
              text: 'Uspješno dodavanje',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.resetInputs()
            this.dialogOpen = false
          } else {
            this.showMsgBox({
              text: 'Dogodila se greška, neuspješno dodavanje',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }
        })
    }
  }

}
</script>
