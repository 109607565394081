export default class AvansDeducted {
  // eslint-disable-next-line space-before-function-paren
  constructor() {
    this.initialPaymentMethod = ''
    this.cashRegister = undefined
    this.location = undefined
    this.items = []
    this.pdvs = []
    this.totalMp = 0
    this.totalVp = 0
    this.discount = {}
    this.deliveryDate = 0
    this.associate = {}
  }
}
