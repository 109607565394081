<template>
  <v-container fluid
    :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3"
        width="97%"
        height="70">
        <v-row>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportFromMenu"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-menu v-model="reportToMenu"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false">
              </v-date-picker>
            </v-menu>
          </div>
          <div style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            ">
            <v-select outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object></v-select>
          </div>
          <div style="
              max-width: 25% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
            ">
            <v-btn :disabled="locationNotSelected"
              class="mr-3"
              @click="getData()"><v-icon>mdi-magnify</v-icon></v-btn>

            <v-btn class="mr-2"
              @click="changeWidth()"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
            <v-btn class="ml-2"
              @click="generateExcel()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
            <v-btn class="ml-3"
              :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        ">
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma"
          style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Codes by Quackit.com -->

        <!--  -->
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title">
            <thead>
              <tr class="pb-0 mb-0">
                <th colspan="6"
                  class="pb-0 mb-0">
                  <p style="font-weight: bold; font-size: 25px">
                    Rekapitulacija robno materijalnih kartica
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div justify="center">
          <table justify="center"
            style="width: 100% !important; table-layout: fixed;"
            border="1"
            cellspacing="0"
            bordercolor="#cacccb"
            id="tableTraffic"
            class="tableTraffic"
            v-if="showTableData && reportData">
            <colgroup>
              <col style="width: 20%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
              <col style="width: 10%;">
            </colgroup>
            <tbody style="min-width: 100% !important">
              <tr style="text-align: left; font-weight: bold; background-color: #dcdcdc; min-width: 100% !important;">
                <td style="text-align: center;">Artikl</td>
                <td style="text-align: center;">Cijena</td>
                <td style="text-align: center;">Ulaz</td>
                <td style="text-align: center;">Vrijednost</td>
                <td style="text-align: center;">Izlaz</td>
                <td style="text-align: center;">Vrijednost</td>
                <td colspan="2"
                  style="text-align: center">Zaliha</td>
                <td style="text-align: center;">Vrijednost</td>

              </tr>
              <tr v-for="item in reportData.items"
                :key="item.id"
                style="text-align: left;  min-width: 100% !important">
                <td style="text-transform: capitalize; padding-left: 3px; padding-right: 3px;">{{ item.name }}</td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.spc.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (item.spc)
                }}
                </td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.inQuantity.toLocaleString('hr-HR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                    : (item.inQuantity)
                }}
                </td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.inValue.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (item.inValue)
                }}
                </td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.outQuantity.toLocaleString('hr-HR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                    : (item.outQuantity)
                  }}
                </td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.outValue.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (item.outValue)
                }}
                </td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.stock.toLocaleString('hr-HR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                    : (item.stock)
                }}
                </td>
                <td style="text-align: left; padding-left: 3px; padding-right: 3px;">{{ item.unit }}</td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    item.stockValue.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (item.stockValue)
                }}
                </td>
              </tr>

              <tr style="text-align: left; font-weight: bold;  background-color: #dcdcdc; min-width: 100% !important;">
                <td style="text-align: center;"
                  colspan="3">Ukupno</td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    reportData.totalInValue.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (reportData.totalInValue)
                }}
                </td>
                <td></td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    reportData.totalOutValue.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (reportData.totalOutValue)
                }}
                </td>
                <td></td>
                <td></td>
                <td style="text-align: right; padding-left: 3px; padding-right: 3px;">
                  {{ viewType === "pdf" ?
                    reportData.total.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
                    : (reportData.total)
                }}
                </td>

              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <td colspan="6"
                  style="text-align: right; padding: 10px; background-color: #f5f5f5;">
                  <button @click="reloadData"
                    style="border: none; background: transparent; cursor: pointer;">
                    <span style="font-size: 24px; color: #757575;">&#x27A1;</span>
                  </button>
                </td>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdfLandscape, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'
import { getReport } from '@/api/report'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: {},
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      location: null,
      locations: [],
      warehouse: null,
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      currency: 'EUR',
      reportData: { items: [{ id: '', name: 'artikl', in_quantity: '100', out_quantity: '10000', in_value: '10', out_value: '1000', unit: '90', spc: '9000' }] },
      items: [{ id: '', name: 'artikl', in_quantity: '100', out_quantity: '10000', in_value: '10', out_value: '1000', unit: '90', spc: '9000' }],
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      showTableData: false
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())
  },
  methods: {
    async getData () {
      this.showLoader()
      this.reportData = undefined

      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      var dateTo = new Date(this.dateTo)
      dateTo.setHours(23, 59, 59, 999)
      dateTo = dateTo.getTime() / 1000

      const query = df.collection('warehouses')
        .where('location_id', '==', this.location.id)
        .where('selling_warehouse', '==', true)

      const documentSnapshots = await query.get()

      if (documentSnapshots.docs && documentSnapshots.docs.length > 0) {
        this.warehouse = documentSnapshots.docs[0].data()
      } else {
        throw new Error('Warehouse not found')
      }

      const payload = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        report: 'commodity',
        warehouseId: this.warehouse.id
      }

      const response = await getReport(payload)

      if (response.status === 200) {
        this.reportData = response.data.data
        this.hideLoader()
        this.showTableData = true
      }

      if (response.status !== 200) {
        this.showMsgBox({ text: response.data.data.message, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        this.hideLoader()
      }
    },

    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdfLandscape()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)

      doc = await setPageNumber(doc)

      doc.save('Rekapitulacija robno materijalnih kartica.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Rekapitulacija robno materijalnih kartica.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.location = all
    }
  }
}
</script>
<style scoped>
.blank_row {
  height: 25px !important;
  /* overwrites any other rules */
  background-color: #ffffff;
  border: 0px red;
  border: none !important;
}

.tableTraffic {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
