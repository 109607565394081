<template>
  <div>
    <confirm ref="confirmStorno"
      @selection="cancelReceipt" />
    <confirm ref="confirmFiscalize"
      @selection="fiscalizeReceipt" />
    <v-container>
      <v-row class="justify-center">
        <v-col cols="12"
          sm="11"
          lg="11"
          :class="['px-0']">
          <v-col cols="12">
            <v-row>
              <v-col cols="3"
                class="pa-0 ma-0">
                <v-text-field dense
                  :clearable="true"
                  outlined
                  hide-details
                  label="Broj radnog naloga"
                  @keydown.enter="applyFilter()"
                  v-model="numberFilter">
                  <template #append>
                    <v-icon color="#3F97C5"
                      @click="applyFilter">mdi-magnify</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3"
                class="pa-0 ma-0">
                <v-select dense
                  outlined
                  v-model="status"
                  :items="statuses"
                  class="uppercased-input"
                  :loading="loading"
                  @click="resetField('status')"
                  @change="applyFilter()"
                  @click:clear="applyFilter"
                  @keydown.enter="applyFilter"
                  :clearable="true"
                  item-text="name"
                  item-value="id"
                  label="Status">
                </v-select>
              </v-col>
              <v-col cols="3"
                class="pa-0 ma-0">
                <v-autocomplete dense
                  outlined
                  v-model="nameFilter"
                  :items="associates"
                  class="uppercased-input"
                  :loading="loading"
                  @click="resetField('name')"
                  @change="applyFilter()"
                  @click:clear="applyFilter"
                  @keydown.enter="applyFilter"
                  :clearable="true"
                  :item-text="(item) =>
                    item.oibs && item.oibs.length > 0
                      ? `${item.name} ${item.oibs[0]}`
                      : `${item.name}`
                    "
                  item-value="name"
                  label="Kupac"
                  placeholder="Počnite tipkati za pretragu"
                  return-object>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title> {{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="item.oibs && item.oibs.length > 0">{{ item.oibs[0] }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template></v-autocomplete>
              </v-col>
              <v-col cols="3"
                class="pa-0 ma-0">
                <!-- <label class="datepickerLabel">Datum</label> -->
                <date-picker style="width: 100% !important"
                  :clearable="true"
                  prefix-class="xmx"
                  :append-to-body="false"
                  class="datetimepicker"
                  v-model="issueDate"
                  type="date"
                  valueType="timestamp"
                  placeholder="Datum"
                  format="DD.MM.YYYY"
                  :default-value="new Date()"
                  @change="applyFilter()"
                  :popup-style="{
                    down: '+274px',
                  }"></date-picker> </v-col></v-row>
          </v-col>
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(workorder, i) in workorders"
              :key="i"
              hover
              tabindex="0">
              <v-expansion-panel-header height="200"
                :class="['status-ok-exp']"
                hide-actions>
                <v-row>
                  <v-col cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]">
                    <div class="caption grey--text text-center">
                      Broj radnog naloga
                    </div>
                    <div class="text-center"
                      v-html="workorder.workorderNumber"
                      prefix="ABCDE"></div>
                  </v-col>
                  <v-col cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    style="min-width: 100px; max-width: 100%"
                    :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']">
                    <div class="caption grey--text">Kupac</div>
                    <div class="text-capitalize">{{ workorder.customer }}</div>
                  </v-col>
                  <v-col cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding, 'flex-shrink-0']">
                    <div class="caption grey--text text-right">Iznos</div>
                    <div class="text-right">
                      {{ workorder.total | money(100, workorder.currency) }}
                    </div>
                  </v-col>
                  <v-col cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]">
                    <div class="caption grey--text text-center">
                      Datum radnog naloga
                    </div>
                    <div class="text-center">
                      {{ workorder.issue_date_time | local }}
                    </div>
                  </v-col>
                  <v-col cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]">
                    <div class="caption grey--text text-center">
                      Datum isporuke
                    </div>
                    <div class="text-center">
                      {{ workorder.delivery_date | local }}
                    </div>
                  </v-col>
                  <v-col cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]">
                    <div class="d-flex justify-space-between">
                      <div>
                        <div class="caption grey--text text-left">Status</div>
                        <div class="text-left">
                          {{ showStatus(workorder.status) }}
                        </div>
                      </div>
                      <div class="d-flex justify-end align-center pl-2"
                        style="z-index: 999">
                        <v-btn min-height="30"
                          min-width="25"
                          plain
                          rounded
                          @click.native.stop
                          @keypress.enter.prevent="openMenu(workorder)"
                          @click="openMenu(workorder)">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12"
                    sm="5">
                    <div class="d-flex justify-space-between text-center text-subtitle-2">
                      Operater: {{ workorder.seller_id }}
                    </div>
                  </v-col>
                </v-row>
                <v-list>
                  <v-row>
                    <v-col>
                      <div class="text-center text-subtitle-2 one-liner-text font-weight-bold">
                        R. br.
                      </div>
                    </v-col>
                    <v-col>
                      <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                        Naziv
                      </div>
                    </v-col>
                    <v-col>
                      <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                        Količina
                      </div>
                    </v-col>
                    <v-col>
                      <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                        Jedinična cijena
                      </div>
                    </v-col>
                    <v-col>
                      <div class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold">
                        Cijena
                      </div>
                    </v-col>
                  </v-row>
                  <v-list-item v-for="(item, index) in workorder.workorder_items"
                    :key="index"
                    class="pa-0">
                    <v-row class="pa-0">
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ index + 1 }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.name }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.amt / multiplier }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{
                            item.single_item_price
                            | money(100, workorder.currency)
                          }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.price | money(100, workorder.currency) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <infinite-loading ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <generate-receipt-from-offer ref="receiptFromOffer" />
      <generate-receipt-from-virman ref="receiptFromVirman" />
      <send-email ref="ordinaryEmail"
        @receiptEdit="onReceiptEdit"></send-email>
      <send-email ref="clientWarning"
        @receiptEdit="onReceiptEdit"></send-email>
      <pay-receipt ref="payReceipt"
        @receiptEdit="onReceiptEdit" />
      <work-order-status-change ref="workOrderStatusChange"
        @receiptEdit="onReceiptEdit"
        @refresh="applyFilter"></work-order-status-change>
      <payment-change ref="paymentChanges"
        @receiptEdit="onReceiptEdit"
        @reprintReceipt="onReceiptReprint"></payment-change>
      <add-tip ref="addTip"
        @receiptEdit="onReceiptEdit"
        @reprintReceipt="onReceiptReprint"></add-tip>
    </v-container>
    <change-receipt-type-dialog ref="chengeReceiptTypeDialog"></change-receipt-type-dialog>
    <create-receipt-from-work-order ref="createReceiptFromWorkOrder"></create-receipt-from-work-order>
    <create-offer-from-work-order ref="createOfferFromWorkOrder"></create-offer-from-work-order>
    <edit-work-order-dialog ref="editWorkOrderDialog"></edit-work-order-dialog>
    <work-order-menu ref="workOrderMenu"
      @menuClicked="onMenuClicked"></work-order-menu>
  </div>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth, functions } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import InfiniteLoading from 'vue-infinite-loading'
import Confirm from '@/components/Confirm.vue'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone, toISOLocal } from '@/plugins/utils'
import GenerateReceiptFromOffer from '@/modules/company/components/GenerateReceiptFromOffer'
import GenerateReceiptFromVirman from '@/modules/company/components/GenerateReceiptFromVirman'
import ChangeReceiptTypeDialog from '@/modules/cash-register/components/dialogs/ChangeReceiptTypeDialog.vue'
import PayReceipt from '@/modules/company/components/PayReceipt'
import SendEmail from '@/modules/company/components/SendEmail'
import WorkOrderStatusChange from '@/modules/point-of-sale/components/workorder/WorkOrderStatusChange'
import PaymentChange from '@/modules/company/components/PaymentChange'
import AddTip from '@/modules/company/components/AddTip'
import { reprint } from '@/libs/bixolon/reprint'
import CreateReceiptFromWorkOrder from '@/modules/point-of-sale/components/workorder/CreateReceiptFromWorkOrder'
import CreateOfferFromWorkOrder from '@/modules/point-of-sale/components/workorder/CreateOfferFromWorkOrder'
import EditWorkOrderDialog from '@/modules/point-of-sale/components/workorder/EditWorkOrderDialog'
import EventBus from '@/plugins/event-bus'
import WorkOrderMenu from '@/modules/point-of-sale/components/workorder/WorkOrderMenu.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'

export default {
  components: {
    InfiniteLoading,
    Confirm,
    GenerateReceiptFromOffer,
    PayReceipt,
    SendEmail,
    WorkOrderStatusChange,
    GenerateReceiptFromVirman,
    PaymentChange,
    ChangeReceiptTypeDialog,
    CreateReceiptFromWorkOrder,
    AddTip,
    WorkOrderMenu,
    EditWorkOrderDialog,
    CreateOfferFromWorkOrder,
    DatePicker
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      modal: false,
      loading: false,
      workorders: [],
      receiptsRaw: [],
      unfiskReceipts: [],
      location: {},
      locations: [],
      associates: [],
      statuses: [
        { name: 'Najava', id: 'INITIAL' },
        { name: 'Povrđen', id: 'CONFIRMED' },
        { name: 'Otkazan', id: 'CANCELED' },
        { name: 'Završen', id: 'COMPLETED' }
      ],
      status: undefined,
      statusFilter: {},
      nameFilter: undefined,
      nameFilterManual: undefined,
      receiptNumberFilter: '',
      receiptNumberParsed: null,
      filterChoosen: '',
      loadingReceipts: false,
      lastVisible: {},
      isExecuted: false,
      listeners: [],
      increment: 1,
      selected: {},
      reciptOffer: {},
      multiplier: 0,
      show: false,
      fiscalizing: false,
      filtersExpanded: true,
      expandIcon: 'mdi-minus',
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      loadingDialog: false,
      registerIdFilter: undefined,
      locationIdFilter: undefined,
      lastBalanceTimestampFilter: undefined,
      hideFilter: false,
      numberFilter: undefined,
      issueDate: undefined
    }
  },
  computed: {

    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    }
  },
  watch: {
    location: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.receipts = []
          this.unfiskReceipts = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    statusFilter: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.receipts = []
          this.unfiskReceipts = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  mounted () {
    this.nameFilterManual = ''

    if (this.$attrs.receiptFilter) {
      this.nameFilterManual = this.$attrs.receiptFilter.nameFilter
      this.reciptOffer = this.$attrs.receiptFilter.reciptOffer
      this.modal = this.$attrs.receiptFilter.modal
    }

    if (this.$attrs.receiptFilter?.registerId) {
      this.registerIdFilter = this.$attrs.receiptFilter.registerId
    }
    if (this.$attrs.receiptFilter?.locationId) {
      this.locationIdFilter = this.$attrs.receiptFilter.locationId
    }
    if (this.$attrs.receiptFilter?.lastBalanceTimestamp) {
      this.lastBalanceTimestampFilter = this.$attrs.receiptFilter.lastBalanceTimestamp
    }
    if (this.$attrs.receiptFilter?.hideFilter) {
      this.hideFilter = this.$attrs.receiptFilter.hideFilter
    }

    this.getAssociates()
    this.getMultiplier()
    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    // this.location = this.locations[0]

    // this.reciptOffer = this.reciptOfferChoices[0].value

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)

    this.receiptNumberFilter = ''

    EventBus.$off('stopButtonLoader')
    EventBus.$on('stopButtonLoader', (data) => {
      this.loadingDialog = false
    })
  },
  methods: {
    showStatus (status) {
      if (status?.current_status) {
        switch (status.current_status) {
          case 'INITIAL':
            return 'NAJAVA'
          case 'CONFIRMED':
            return 'POTVRĐEN'
          case 'CREATED':
            return 'KREIRAN'
          case 'UPDATED':
            return 'IZMJENJEN'
          case 'CANCELED':
            return 'OTKAZAN'
          case 'COMPLETED':
            return 'ZAVRŠEN'
        }
      } else {
        return 'KREIRAN'
      }
    },
    editWorkOrder (workorder) {
      this.loadingDialog = true
      this.$refs.editWorkOrderDialog.open(this.loadingDialog, workorder)
    },
    createOfferFromWorkOrder (workorder) {
      this.loadingDialog = true
      this.$refs.createOfferFromWorkOrder.open(this.loadingDialog, workorder)
    },
    createReceiptFromWorkOrder (workorder) {
      this.loadingDialog = true
      this.$refs.createReceiptFromWorkOrder.open(this.loadingDialog, workorder)
    },
    changeWorkOrderStatus (workorder) {
      this.loadingDialog = true
      this.$refs.workOrderStatusChange.open(workorder)
    },
    changeBalanceTimestampFilter (balanceTimestamp) {
      if (balanceTimestamp) {
        this.lastBalanceTimestampFilter = balanceTimestamp
      }
      this.applyFilter()
    },
    setReceiptType (receiptOffer) {
      this.reciptOffer = receiptOffer
    },
    printWorkOrder (workorder) {
      this.showLoader()
      var printTemplate = ''
      if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
        printTemplate = state.getCurrentCompany().print_template
      }

      var receipttemplate = functions.httpsCallable('receiptTemplate')

      receipttemplate({
        template: printTemplate,
        companyId: workorder.company_details.id,
        documentId: workorder.id,
        documentType: 'radninalog',
        rawJson: ''
      })
        .then((result) => {
          this.hideLoader()
          if (result.data?.document !== '') {
            this.openPdfPrint(result.data?.document)
          }
        }
        )
    },
    openCrudDialog () {
      this.loadingDialog = true
      this.$refs.crudTemplateDialog.open(this.loadingDialog)
    },
    onMenuClicked (item, workorder) {
      var func = item.action
      func(workorder)
    },
    openMenu (workorder) {
      this.$refs.workOrderMenu.open(workorder, 1)
    },
    onReceiptReprint (receipt) {
      reprint('Printer1', receipt, 2)
    },

    reprintReceipt (receipt) {
      reprint('Printer1', receipt, 0)
    },

    applyFilter () {
      this.lastVisible = {}
      this.workorders = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },

    changeReceiptType (receipt) {
      this.$refs.chengeReceiptTypeDialog.open(receipt)
    },

    issueBefore (issueDateTime) {
      const tmp = new Date(
        toISOLocal(
          new Date(issueDateTime * 1000)
        )
      )

      tmp.setDate(tmp.getDate() + 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )

      return before
    },
    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())

          _document.workorderOptions = [
            { title: 'Uredi radni nalog', action: this.editWorkOrder },
            { title: 'Preuzmi radni nalog', action: this.printWorkOrder },
            { title: 'Pošalji radni nalog E-mailom', action: this.sendWorkOrderEmail },
            { title: 'Kreiraj ponudu', action: this.createOfferFromWorkOrder },
            { title: 'Kreiraj račun', action: this.createReceiptFromWorkOrder },
            { title: 'Promijeni status', action: this.changeWorkOrderStatus }
          ]

          _document.customer = _document.type.payer_name

          if (_document.designation) {
            _document.workorderNumber = `RN-${_document.designation.number}`
          }

          if (_document.issue_date_time) {
            _document.time = _document.issue_date_time.seconds
          }

          _document.show = false

          this.workorders.push({
            ..._document,
            ...{ [`hover-${change.doc.data().id}`]: false }
          })
          // }

          this.workorders.sort((a, b) => b.issue_date_time - a.issue_date_time) // b - a for reverse sort
          this.$forceUpdate()
        }
      }
      if (change.type === 'modified') {
        // TODO: duplicated code, make 1 fun
        this.receipts = this.receipts.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            const _document = clone(change.doc.data())

            _document.workorderOptions = [
              { title: 'Uredi radni nalog', action: this.editWorkOrder },
              { title: 'Preuzmi radni nalog', action: this.printWorkOrder },
              { title: 'Pošalji radni nalog E-mailom', action: this.sendWorkOrderEmail },
              { title: 'Kreiraj ponudu', action: this.createOfferFromWorkOrder },
              { title: 'Kreiraj račun', action: this.createReceiptFromWorkOrder },
              { title: 'Promijeni status', action: this.changeWorkOrderStatus }
            ]

            _document.customer = _document.type.payer_name

            if (_document.designation) {
              _document.workorderNumber = `${_document.designation.number}`
            }

            if (_document.issue_date_time) {
              _document.time = _document.issue_date_time.seconds
            }

            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.workorders = this.workorders.filter((document, index, self) =>
        index === self.findIndex((t) => (
          t.id === document.id
        ))
      )
    },
    infiniteHandler ($state) {
      const that = this
      const where = that.location ? that.location.location_id : ''

      let query = df
        .collection(
          `work_orders/${state.getCurrentCompany().id}/work_orders`
        )
        .orderBy('issue_date_time', 'desc')
        .startAfter(that.lastVisible)
        .limit(10)

      if (where) {
        query = query.where('designation.location_id', '==', where)
      }

      if (this.numberFilter) {
        query = query.where('designation.number', '==', parseInt(this.numberFilter))
      }

      if (this.status) {
        query = query.where('status.current_status', '==', this.status)
      }

      if (this.issueDate) {
        const endDt = new Date(this.issueDate)

        // Postavi vrijeme na 23:59:59
        endDt.setHours(23)
        endDt.setMinutes(59)
        endDt.setSeconds(59)

        // Vrati timestamp za 23:59:59
        var end = endDt.getTime()

        query = query.where('issue_date_time', '>=', this.issueDate / 1000)
        query = query.where('issue_date_time', '<=', end / 1000)
      }

      const nameFilter = this.nameFilter

      if (nameFilter) {
        // provjera da li je uneseni filter broj (oib) ili ne
        let argument
        if (!isNaN(nameFilter)) {
          // nameFilter je OIB
          argument = nameFilter
        } else {
          if (typeof (nameFilter) === 'object') {
            // nameFilter je račun
            argument = nameFilter.name
          } else if (typeof (nameFilter) === 'string') {
            // nameFilter je string
            argument = nameFilter
          }
        }
        query = query.where('type.payer_name', '==', argument)
      }

      try {
        const listener = query.onSnapshot(
          doc => {
            doc.docChanges().forEach(change => {
              that.modifyItems(change)
            })
            if (doc && !doc.empty) {
              if (doc.docs[doc.docs.length - 1]) {
                that.lastVisible = doc.docs[doc.docs.length - 1]
              }
              $state.loaded()
            } else {
              $state.complete()
            }
          }

        )

        this.listeners.push(listener)
      } catch (error) {
        console.log(error)
      }
      this.workorders = this.workorders.sort((a, b) => { return a.issue_date_time < b.issue_date_time }

      )
    },
    getReceiptsOrOffers () {
      this.lastVisible = ''
      this.receipts = []
      this.unfiskReceipts = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    stornoReceipt (receipt) {
      this.$refs.confirmStorno.open(
        '',
        `Storniraj račun ${receipt.receiptNumber}?`,
        { confirmText: 'Da', cancelText: 'Ne' }
      )
      this.selected = receipt
    },
    changePayment (receipt) {
      this.$refs.paymentChanges.open(receipt)
    },
    addTip (receipt) {
      this.$refs.addTip.open(receipt)
    },
    sendWorkOrderEmail (receipt) {
      this.$refs.ordinaryEmail.open(receipt, {
        action: 'WORKORDER_SENT',
        title: 'Pošalji radni nalog mailom',
        okBtn: 'Pošalji Email'
      })
    },

    printReceipt (receipt, preview = false) {
      const that = this
      try {
        that.showLoader()
        const reqId = uuidv4()
        let entity = 'receipt'
        if (this.reciptOffer === 'offers') {
          entity = 'offer'
        }

        if (this.reciptOffer === 'virmans') {
          entity = 'virman'
        }

        const payload = {
          action: {
            operation: 'get',
            entity: entity,
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id,
              print_size: 'A4'
            }
          }
        }

        if (that.reciptOffer === 'offers') {
          payload.action.entity = 'offer'
          payload.action.params.print_size = 'A4'
        }

        if (that.reciptOffer === 'virmans') {
          payload.action.entity = 'virman'
          payload.action.params.print_size = 'A4'
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          })
          .then(function () { })
          .catch(function (err) {
            that.hideLoader()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            // this.$refs.createBusinessForm.reset()
            unsubscribe()
            if (!doc.data().body || !doc.data().body.document) {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.nullReceipt'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              return
            }

            if (preview) {
              that.openPdfPrint(doc.data().body.document)
              that.hideLoader()
            } else {
              const linkSource = `data:application/pdf;base64,${doc.data().body.document
                }`
              const downloadLink = document.createElement('a')
              const fileName = `${receipt.receiptNumber}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              that.hideLoader()
            }
          }
        })
      } catch (err) {
        that.hideLoader()
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    cancelReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        let receiptNumber = receipt.designation ? receipt.designation.number : 0
        df.doc(`cash_registers/${receipt.designation.register_id}`)
          .get()
          .then(reg => {
            if (reg && reg.exists) {
              if (reg.data()) {
                if (reg.data().status !== 'OK') {
                  this.$refs.confirmStorno.close()
                  that.showMsgBox({
                    text: that.$t('$vuetify.errors.unknownRegister'),
                    actions: ['cancel'],
                    cancelBtnText: that.$t('$vuetify.close'),
                    color: 'error'
                  })
                  return
                }
                if (this.reciptOffer === 'receipts') {
                  receiptNumber = reg.data().bill_sequence || 0
                } else if (this.reciptOffer === 'offers') {
                  receiptNumber = reg.data().offer_sequence || 0
                }
                const payload = {
                  action: {
                    operation: 'set',
                    entity: 'storno',
                    params: {
                      company_id: state.getCurrentCompany().id,
                      location_id: receipt.location_details.id,
                      receipt_id: receipt.id,
                      register_id: receipt.designation.register_id,
                      issue_date_time: parseInt(
                        (new Date().getTime() / 1000).toFixed(0)
                      ),
                      number: receiptNumber + 1
                      // seller_id: auth.currentUser.uid,
                    }
                  }
                }

                that.showLoader()
                that.$refs.confirmStorno.close()
                df.doc(`request/${reqId}`)
                  .set({
                    user_id: `${auth.currentUser.uid}`,
                    device_id: 'web',
                    created: `${new Date().getTime()}`,
                    type: 'receipt',
                    payload: btoa(
                      unescape(encodeURIComponent(JSON.stringify(payload)))
                    )
                  })
                  .then(function () {
                    setTimeout(() => {
                      that.hideLoader()
                      that.lastVisible = {}
                      that.receipts = []
                      that.unfiskReceipts = []
                      that.$refs.InfiniteLoading.stateChanger.reset()
                    }, 5500)
                  })
                  .catch(function (err) {
                    that.hideLoader()
                    that.showMsgBox({
                      text: err && err !== '' ? err : 'An error has occurred',
                      actions: ['cancel'],
                      cancelBtnText: 'OK',
                      color: 'error'
                    })
                  })
              }
            } else {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.unknownRegister'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              this.$refs.confirmStorno.close()
            }
          })
      } catch (err) {
        this.$refs.confirmStorno.close()
        that.hideLoader()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    generateReceipt (receipt) {
      if (receipt && receipt.type && receipt.type.flag && receipt.type.flag === 'V') {
        this.$refs.receiptFromVirman.open(receipt, this.multiplier)
      } else {
        this.$refs.receiptFromOffer.open(receipt, this.multiplier)
      }
    },
    payReceipt (receipt) {
      this.$refs.payReceipt.open(receipt)
    },
    onReceiptEdit (receipt) {
      this.receipts = this.receipts.map(r => {
        if (r.id === receipt.id) {
          r.payment.current_status = 'PROCESSING'
        }
        return r
      })
    },
    sendOrdinaryEmail (receipt) {
      this.$refs.ordinaryEmail.open(receipt, {
        action: 'EMAIL_SENT',
        title: 'Pošalji račun mailom',
        okBtn: 'Pošalji EMail'
      })
    },
    sendEmailOffer (receipt) {
      this.$refs.ordinaryEmail.open(receipt, {
        action: 'OFFER_SENT',
        title: 'Pošalji ponudu mailom',
        okBtn: 'Pošalji EMail'
      })
    },
    sendWarningToClient (receipt) {
      this.$refs.clientWarning.open(receipt, {
        action: 'WARNING_SENT',
        title: 'Opomeni korisnika',
        okBtn: 'Opomeni'
      })
    },
    statusToUnpaid (receipt) {
      this.$refs.statusToUnpaid.open(receipt)
    },
    openFiscalizeConfirm (receipt) {
      this.$refs.confirmFiscalize.open(
        '',
        `Fiskaliziraj račun ${receipt.receiptNumber}?`,
        { confirmText: 'Da', cancelText: 'Ne' }
      )
      this.selected = receipt
    },
    fiscalizeReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'fiscalize',
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id
            }
          }
        }

        that.showLoader()
        that.$refs.confirmFiscalize.close()
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
          })
          .catch(function (err) {
            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().header || !doc.data().header.code || doc.data().header.code !== 200) {
              that.showMsgBox({
                text: doc.data().header.error ? doc.data().header.error : 'Nije uspjela fiskalizacija računa!',
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              that.lastVisible = {}
              that.receipts = []
              that.unfiskReceipts = []
              that.$refs.InfiniteLoading.stateChanger.reset()
              that.$refs.confirmFiscalize.close()
              return
            }

            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
          }
        })
      } catch (err) {
        that.hideLoader()

        that.lastVisible = {}
        that.receipts = []
        that.unfiskReceipts = []
        that.$refs.InfiniteLoading.stateChanger.reset()
        that.$refs.confirmFiscalize.close()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },

    onExpandFilters () {
      this.filtersExpanded = !this.filtersExpanded
      this.expandIcon = this.filtersExpanded ? 'mdi-minus' : 'mdi-plus'
    },

    parseReceiptNumber (receiptNumber) {
      receiptNumber = receiptNumber.replaceAll('/', '-')
      const parts = receiptNumber.split('-')
      if (parts.length !== 3) {
        return {
          number: 0,
          location_id: '',
          register_number: 0
        }
      }
      return { number: parseInt(parts[0]), location_id: parts[1], register_number: parseInt(parts[2]) }
    },

    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)

            this.defaultAssociates.push({ name: 'Kupac građanin', id: 'kupac-gradanin', oib: '' })

            this.associates = clone(this.defaultAssociates)
          }
        })
    },
    resetField (field) {
      field === 'name' ? this.nameFilterManual = '' : this.nameFilter = undefined
      if (field === 'status') this.status = undefined
      this.applyFilter()
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.new-receipt-fab {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.datepickerLabel {
  font-size: 12px !important;
  font-weight: bold;
}
</style>
