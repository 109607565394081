<template>
  <div style="border-radius: 25px !important">
    <v-dialog v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      persistent
      max-width="450"
      style="height: 400px !important">
      <v-overlay v-if="loading"
        z-index="1000"
        style="height: 100%; width: 100%; opacity: 1 !important">
        <v-progress-circular :size="100"
          :width="10"
          style="color: #04aa6d; z-index: 1000"
          indeterminate>Pričekajte</v-progress-circular>
      </v-overlay>
      <v-form>
        <v-card v-if="field"
          tile
          color="white"
          class="pb-3"
          style="border-radius: 25px !important; height: 500px !important">
          <v-card-title class="d-flex"
            style="
              vertical-align: middle;
              background-color: #1577da;

              height: 58px;
              color: white;
            ">
            <span class="text-h5"
              style="font-weight: bold">Uredi dodatno polje</span>
          </v-card-title>
          <v-card-text style="padding: 0; margin: 0; overflow-x: hidden">

            <v-row class="flex-wrap justify-center pr-4 pl-4">
              <v-col>
                <v-row class="pt-0 mt-2 pb-0 mb-0">
                  <v-col cols="12"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field type="number"
                      dense
                      label="Redni broj"
                      outlined
                      hide-details
                      v-model="field.order_number"
                      :rules="[rules.req]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0 mt-2 pb-0 mb-0">
                  <v-col cols="12"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-select dense
                      label="Tip polja"
                      outlined
                      hide-details
                      v-model="field.field_type"
                      :items="availableTypes"
                      item-text="name"
                      item-value="id"
                      :rules="[rules.req]"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      hide-details
                      outlined
                      label="Labela"
                      v-model="field.label"
                      :rules="[rules.req]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      hide-details
                      outlined
                      label="Zadana vrijednost"
                      v-model="field.default_value">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6"
                    class="pl-2 pb-1">
                    <v-checkbox class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      outlined
                      hide-details
                      label="Prikaži na ispisu"
                      v-model="field.printable">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6"
                    class="pl-2 pr-0 pb-1">
                    <v-checkbox class="pt-0 mt-0 pb-0 mb-0"
                      dense
                      outlined
                      hide-details
                      label="Obavezan parametar"
                      v-model="field.mandatory">
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="field.field_type === 'dropdown'"
                  class="pt-0 mt-0 pb-0 mb-0">
                  <div style="width: 47.5%"
                    class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-combobox clearable
                      :items="availableDropdownValues"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Predefinirane vrijednosti"
                      v-model="dropdownValue"
                      :search-input.sync="dropdownValue">
                    </v-combobox>
                  </div>
                  <div style="width: 15%"
                    class="pl-6 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton"
                      @click="addDropdownValue()"><v-icon>mdi-plus-outline</v-icon></v-btn>
                  </div>
                  <!-- <div style="width: 15%" class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton" @click="saveDropdownValue()"
                      ><v-icon>mdi-content-save-check-outline</v-icon></v-btn
                    >
                  </div> -->
                </v-row>
                <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12"
                    v-if="
                      field.field_type === 'dropdown' &&
                      field.dropdown_values &&
                      field.dropdown_values.length > 0
                    ">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%"
                            colspan="1">Br.</th>
                          <th width="80%"
                            colspan="1">Predefinirani odabir</th>
                          <th width="10%"
                            colspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in field.dropdown_values"
                          :key="ct">
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            {{ key + 1 }}
                          </td>
                          <td style="text-align: center"
                            width="80%"
                            colspan="1">
                            {{ ct }}
                          </td>
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            <v-icon @click="removeaddedDropdownValues(key)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>

                <v-row v-if="field.field_type === 'radio'"
                  class="pt-0 mt-0 pb-0 mb-0">
                  <div style="width: 47.5%"
                    class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-combobox clearable
                      :items="availableRadioValues"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Predefinirane vrijednosti"
                      v-model="radioValue"
                      :search-input.sync="radioValue">
                    </v-combobox>
                  </div>
                  <div style="width: 15%"
                    class="pl-6 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton"
                      @click="addRadioValue()"><v-icon>mdi-plus-outline</v-icon></v-btn>
                  </div>
                </v-row>
                <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12"
                    v-if="
                      field.field_type === 'radio' &&
                      field.radio_values &&
                      field.radio_values.length > 0
                    ">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%"
                            colspan="1">Br.</th>
                          <th width="80%"
                            colspan="1">Predefinirani odabir</th>
                          <th width="10%"
                            colspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in field.radio_values"
                          :key="ct">
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            {{ key + 1 }}
                          </td>
                          <td style="text-align: center"
                            width="80%"
                            colspan="1">
                            {{ ct }}
                          </td>
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            <v-icon @click="removeaddedRadioValues(key)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>

                <v-row v-if="field.field_type === 'checkbox'"
                  class="pt-0 mt-0 pb-0 mb-0">
                  <div style="width: 47.5%"
                    class="pl-3 pt-0 mt-5 pb-0 mb-0">
                    <v-combobox clearable
                      :items="availableCheckboxValues"
                      class="pt-0 mt-0 pb-0 mb-0 uppercased-input"
                      dense
                      hide-details
                      outlined
                      label="Predefinirane vrijednosti"
                      v-model="checkboxValue"
                      :search-input.sync="checkboxValue">
                    </v-combobox>
                  </div>
                  <div style="width: 15%"
                    class="pl-6 pt-0 mt-5 pb-0 mb-0">
                    <v-btn class="btn infoButton"
                      @click="addCheckboxValue()"><v-icon>mdi-plus-outline</v-icon></v-btn>
                  </div>
                  <div style="width: 15%"
                    class="pl-9 pt-0 mt-2 pb-0 mb-0">
                    <v-checkbox v-model="checkboxSelected"
                      label="Odabrano"></v-checkbox>
                  </div>
                </v-row>
                <v-row lass="pl-3 pr-3 pt-3 mt-0 pb-0 mb-0">
                  <v-col cols="12"
                    v-if="
                      field.field_type === 'checkbox' &&
                      field.checkbox_values &&
                      field.checkbox_values.length > 0
                    ">
                    <table style="width: 100% !important">
                      <thead>
                        <tr>
                          <th width="10%"
                            colspan="1">Br.</th>
                          <th width="50%"
                            colspan="1">Predefinirani odabir</th>
                          <th width="10%"
                            colspan="1">Odabrano</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ct, key) in field.checkbox_values"
                          :key="key">
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            {{ key + 1 }}
                          </td>
                          <td style="text-align: center"
                            width="50%"
                            colspan="1">
                            {{ ct.name }}
                          </td>
                          <td style="text-align: center"
                            width="30%"
                            colspan="1">
                            {{ ct.selected ? 'Da' : 'Ne' }}
                          </td>
                          <td style="text-align: center"
                            width="10%"
                            colspan="1">
                            <v-icon @click="removeaddedCheckboxValues(key)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex pt-3 mt-0 justify-end">
            <v-btn class="btn dangerButton"
              @click="close"> Odustani </v-btn>
            <v-btn :disabled="checkFinish()"
              id="success"
              class="btn successButton"
              @click="updateField()">
              Završi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: {},
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    dialogOpen: false,
    location: undefined,
    locationId: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: [],
    loading: false,
    availableTypes: [
      { id: 'textfield', name: 'Polje za unos teksta' },
      { id: 'textarea', name: 'Veliko polje za unos teksta' },
      { id: 'dropdown', name: 'Odabir predefiniranih vrijednosti' },
      { id: 'date', name: 'Datum' }
    ],
    field: undefined,
    dropdownValue: '',
    availableDropdownValues: [],
    addedDropdownValues: [],
    radioValue: '',
    availableRadioValues: [],
    addedRadioValues: [],
    checkboxValue: '',
    availableCheckboxValues: [],
    addedCheckboxValues: [],
    checkboxSelected: false
  }),
  mounted () {
    this.getAvailableDropdownValues()
    this.getAvailableRadioValues()
    this.getAvailableCheckboxValues()
  },
  methods: {
    checkFinish () {
      if (!this.field.label || this.field.label === '') {
        return true
      }

      if (!this.field.field_type || this.field.field_type === '') {
        return true
      }

      return false
    },
    removeaddedDropdownValues (index) {
      this.field.dropdown_values.splice(index, 1)
    },
    async getAvailableDropdownValues () {
      this.availableDropdownValues = []
      const snapshot = await df.doc(`workorder_dropdown_value/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.values && document.values.length > 0) {
          this.availableDropdownValues = [...document.values]
          this.availableDropdownValues.sort((a, b) => a.localeCompare(b))
        }
      }
    },
    addDropdownValue () {
      this.field.dropdown_values.push(this.dropdownValue)
    },
    async saveDropdownValue (added) {
      var newDropDownValues = [...this.availableDropdownValues]
      newDropDownValues.push(added)

      if (this.availableDropdownValues.length === 0) {
        const docRef = df.doc(`workorder_dropdown_value/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            values: newDropDownValues
          })
      } else {
        df.doc(`workorder_dropdown_value/${state.getCurrentCompany().id}`)
          .update({
            values: newDropDownValues
          })
      }

      this.getAvailableDropdownValues()
    },

    removeaddedRadioValues (index) {
      this.field.radio_values.splice(index, 1)
    },
    async getAvailableRadioValues () {
      this.availableRadioValues = []
      const snapshot = await df.doc(`workorder_radio_value/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.values && document.values.length > 0) {
          this.availableRadioValues = [...document.values]
          this.availableRadioValues.sort((a, b) => a.localeCompare(b))
        }
      }
    },
    addRadioValue () {
      this.field.radio_values.push(this.radioValue)
    },
    async saveRadioValue (added) {
      var newRadioValues = [...this.availableRadioValues]
      newRadioValues.push(added)

      if (this.availableRadioValues.length === 0) {
        const docRef = df.doc(`workorder_radio_value/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            values: newRadioValues
          })
      } else {
        df.doc(`workorder_radio_value/${state.getCurrentCompany().id}`)
          .update({
            values: newRadioValues
          })
      }

      this.getAvailableRadioValues()
    },

    removeaddedCheckboxValues (index) {
      this.field.checkbox_values.splice(index, 1)
    },
    async getAvailableCheckboxValues () {
      this.availableCheckboxValues = []
      const snapshot = await df.doc(`workorder_checkbox_value/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        if (document?.values && document.values.length > 0) {
          this.availableCheckboxValues = [...document.values]
          this.availableCheckboxValues.sort((a, b) => a.name.localeCompare(b.name))
        }
      }
    },
    addCheckboxValue () {
      this.field.checkbox_values.push({ name: this.checkboxValue, selected: this.checkboxSelected })
      this.checkboxSelected = false
    },
    async saveCheckboxValue (added) {
      var newCheckboxValues = [...this.availableCheckboxValues]
      newCheckboxValues.push(added)

      if (this.availableCheckboxValues.length === 0) {
        const docRef = df.doc(`workorder_checkbox_value/${state.getCurrentCompany().id}`)
        await docRef.set(
          {
            values: newCheckboxValues
          })
      } else {
        df.doc(`workorder_checkbox_value/${state.getCurrentCompany().id}`)
          .update({
            values: newCheckboxValues
          })
      }

      this.getAvailableCheckboxValues()
    },

    open (field) {
      this.getAvailableDropdownValues()
      this.getAvailableRadioValues()
      this.getAvailableCheckboxValues()

      this.field = field
      this.dialogOpen = true
      this.submitting = false
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.field = undefined
      this.dropdownValue = ''
      this.availableDropdownValues = []
      this.addedDropdownValues = []
      this.radioValue = ''
      this.availableRadioValues = []
      this.addedRadioValues = []
      this.checkboxValue = ''
      this.availableCheckboxValues = []
      this.addedCheckboxValues = []
      this.dialogOpen = false
    },
    updateField () {
      if (!this.field.label || this.field.label === '') {
        return
      }

      this.loading = true

      this.addedDropdownValues.forEach(added => {
        if (!this.availableDropdownValues.includes(added)) {
          this.saveDropdownValue(added)
        }
      })

      this.addedRadioValues.forEach(added => {
        if (!this.addedRadioValues.includes(added)) {
          this.saveRadioValue(added)
        }
      })

      this.addedCheckboxValues.forEach(added => {
        if (!this.addedCheckboxValues.includes(added)) {
          this.saveCheckboxValue(added)
        }
      })

      var workorderApi = functions.httpsCallable('workorderapi')

      var params = {
        action: {
          operation: 'update',
          entity: 'field',
          resource_id: '',
          params: {
            id: this.field.id,
            label: this.field.label,
            field_type: this.field.field_type,
            mandatory: this.field.mandatory,
            default_value: this.field.default_value,
            printable: this.field.printable,
            company_id: state.getCurrentCompany().id,
            order_number: parseInt(this.field.order_number)
          }
        }
      }

      if (this.field.field_type === 'dropdown') {
        params.action.params.dropdown_values = this.field.dropdown_values
      }

      if (this.field.field_type === 'radio') {
        params.action.params.radio_values = this.field.radio_values
      }

      if (this.field.field_type === 'checkbox') {
        params.action.params.checkbox_values = this.field.checkbox_values
      }

      workorderApi(
        params
      )
        .then((result) => {
          this.loading = false
          if (result.data.code === 200) {
            this.$emit('refreshFields')
            this.showMsgBox({
              text: 'Uspješna izmjena',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.resetInputs()
            this.dialogOpen = false
          } else {
            this.showMsgBox({
              text: 'Dogodila se greška, neuspješna izmjena',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }
        })
    }
  }

}
</script>
